import React from 'react'
import styles from './style.module.less'
import { ModalProps } from '@/components/Modal'
import { useTranslation } from 'react-i18next'
import { errorNotice, notice } from '@/common/tip'
import { TaskApi } from '@/services/task'
import { useMediaInfo } from '@/store/useMediaInfo'
import ModalWrapper from '@/components/ModalWrapper'
import { Button } from '@/components/Button'
import { useUserInfo } from '@/store/useUserInfo'

type Props = {
  username?: string | null
  type?: 'Discord' | 'Twitter'
} & ModalProps

const UnbindModal = ({ onDismiss, username, type }: Props) => {
  const { t } = useTranslation()
  const { unbindByType } = useMediaInfo()
  const { info } = useUserInfo()

  const unbindHandle = async () => {
    if (!info) {
      notice(t('task.logFirst'))
      return
    }
    const res = await TaskApi.unbind(info.accountId, type!)
    if (res.data.code === 0) {
      unbindByType(type!)
      notice(t('personal.unbindSuccess'), 'success')
      onDismiss?.()
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <ModalWrapper
      onDismiss={onDismiss}
      title={t('personal.unbind')}
      width={400}
    >
      <div>{`${t('personal.unbindTip1')}${type}${t('personal.unbindTip2')}${
        username || ''
      }${t('personal.unbindTip3')}`}</div>
      <Button className={styles.confirmBtn} onClick={unbindHandle}>
        {t('personal.confirm')}
      </Button>
    </ModalWrapper>
  )
}

export default React.memo(UnbindModal)
