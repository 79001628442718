import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import Input from '@/components/Input'
import { InputProps } from '@/common/types'

type Props = {
  label: string
  extra?: React.ReactNode
  inputExtra?: React.ReactNode
  className?: string
  contentClassName?: string
} & InputProps

const LabelInput: React.FC<Props> = ({
  label,
  extra,
  inputExtra,
  className = '',
  contentClassName,
  value = '',
  onChange,
  defaultValue,
  ...inputProps
}) => {
  const props = defaultValue ? { defaultValue } : { value, onChange }
  return (
    <div className={classNames(styles.labelInput, className)}>
      {label ? (
        <div className={styles.header}>
          <div className={styles.label}>{label}</div>
          {typeof extra !== 'undefined' && (
            <div className={styles.extra}>{extra}</div>
          )}
        </div>
      ) : null}
      <div className={classNames(styles.content, contentClassName)}>
        <input {...props} {...inputProps} className={styles.input} />
        {typeof inputExtra !== 'undefined' && (
          <div className={styles.inputExtra}>{inputExtra}</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(LabelInput)
