import React from 'react'
import styles from './style.module.less'
import { DatePicker } from 'antd'
import type { DatePickerProps } from 'antd'

const StyledDatePicker: React.FC<DatePickerProps> = (props) => {
  return (
    <div className={styles.datePickerCtn}>
      <DatePicker
        {...props}
        className={styles.datePicker}
        popupClassName={styles.popup}
      />
    </div>
  )
}

export default React.memo(StyledDatePicker)
