import React from 'react'
import styles from './style.module.less'
import { DatePicker } from 'antd'
import type { TimeRangePickerProps } from 'antd'

const { RangePicker } = DatePicker

const StyledRangePicker: React.FC<TimeRangePickerProps> = (props) => {
  return (
    <div className={styles.pickerCtn}>
      <RangePicker
        {...props}
        className={styles.datePicker}
        popupClassName={styles.popup}
      />
    </div>
  )
}

export default React.memo(StyledRangePicker)
