import React, { useState } from 'react'
import styles from './style.module.less'
import Tabs from '@/components/Tabs'
import type { TabsProps } from 'antd'
import Module from '@/components/Module'
import { SupportCoin, WalletApi } from '@/services/wallet'
import { getSearchParams } from '@/utils/utils'
import useQuery from '@/hooks/useQuery'
import Pagination from '@/components/Pagination'
import RechargeTable from '../../components/RechargeTable'
import WithdrawTable from '../../components/WithdrawTable'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'
import { ButtonGroup } from '../../components'

const PAGE_SIZE = 10

type Props = {
  supportList: SupportCoin[]
}

const Tables: React.FC<Props> = () => {
  const { t } = useTranslation()
  const { coinId } = getSearchParams()
  const [rechargePageNo, setRechargePageNo] = useState(1)
  const [withdrawPageNo, setWithdrawPageNo] = useState(1)
  const [selectType, setSelectType] = useState(1)

  const { data: depositRecord } = useQuery(() => {
    return WalletApi.getDepositRecord({
      pageNo: rechargePageNo,
      pageSize: PAGE_SIZE,
      coinId: +coinId,
      orderTypes: [1, 3],
      tokenTypes: [1, 2]
    })
  }, [rechargePageNo])

  const { data: rechargeDS = [], totals = 0 } = depositRecord ?? {}

  const { data: withdrawRecord } = useQuery(() => {
    return WalletApi.getWithdrawRecord({
      pageNo: withdrawPageNo,
      pageSize: PAGE_SIZE,
      coinId: +coinId,
      assetType: 1,
      selectType
    })
  }, [selectType, withdrawPageNo])

  const { data: withdrawDS = [], totals: withdrawTotals = 0 } =
    withdrawRecord ?? {}

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('wallet.assets.depositHistory'),
      children: (
        <>
          <Empty data={rechargeDS}>
            <RechargeTable dataSource={rechargeDS} hideOrderId />
            <div className={styles.paginationCtn}>
              <Pagination
                total={+totals}
                showSizeChanger={false}
                onChange={(page) => setRechargePageNo(page)}
                current={rechargePageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        </>
      )
    },
    {
      key: '2',
      label: t('wallet.assets.withdrawHistory'),
      children: (
        <div>
          <div className={styles.search}>
            <ButtonGroup
              options={[
                { value: 1, label: t('wallet.assets.withdrawal') },
                { value: 2, label: t('wallet.assets.internal') }
              ]}
              value={selectType}
              onChange={(v) => setSelectType(v as number)}
            />
          </div>
          <Empty data={withdrawDS}>
            <WithdrawTable dataSource={withdrawDS} hideOrderId />
            <div className={styles.paginationCtn}>
              <Pagination
                total={+withdrawTotals}
                showSizeChanger={false}
                onChange={(page) => setWithdrawPageNo(page)}
                current={withdrawPageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        </div>
      )
    }
  ]

  return (
    <Module className={styles.tables}>
      <Tabs items={items} />
    </Module>
  )
}

export default React.memo(Tables)
