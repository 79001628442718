import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { Page, TitleModule } from '../components'
import { ITEMS_MANAGE } from '@/constants/profile'
import InputBox from '@/components/InputBox'
import Select from '@/components/Select'
import { Form, Space, TableProps } from 'antd'
import { ProfileButton } from '@/components/Button'
import { SearchIcon } from '@/assets'
import Item from './Item'
import Switch from './Switch'
import { ProfileTable } from '@/components/Table'
import ColorfulText from '@/components/ColorfulText'
import { errorNotice } from '@/common/tip'
import { NFT as NFTType, UserAPI } from '@/services/userAPI'
import useQuery from '@/hooks/useQuery'
import { ShopAPI } from '@/services/shopAPI'
import { getLangData, getMediaSrc } from '@/utils/utils'
import { DefaultNFT } from '../image'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'

const { LabelInput } = InputBox

const PAGE_SIZE = 12
const TYPE = 2

const NFT: React.FC = () => {
  const [open, setOpen] = useState(true)
  const [nfts, setNFTs] = useState<NFTType[]>([])
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const [total, setTotal] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [form] = Form.useForm()

  const { data: classificationList = [] } = useQuery(ShopAPI.getClassList, [
    i18n.language
  ])

  async function getNFT() {
    const { keyword, classId } = form.getFieldsValue()
    const res = await UserAPI.getNFTList({
      pageNo,
      pageSize: PAGE_SIZE,
      type: TYPE,
      keyword,
      classId
    })
    if (res.data.code === 0) {
      setNFTs(res.data.data.records)
      setTotal(+res.data.data.total)
    } else {
      errorNotice(res.data.message)
    }
  }

  useEffect(() => {
    getNFT()
  }, [pageNo])

  function toDetail(id: string) {
    history.push(`/profile/item-detail?id=${id}`)
  }

  const columns: TableProps<NFTType>['columns'] = [
    {
      title: t('profile.icon'),
      dataIndex: 'showImageUrl',
      key: 'showImageUrl',
      align: 'center',
      width: 100,
      render(val, item) {
        const src = getMediaSrc(item.showImageUrl) || DefaultNFT
        return <img className={styles.avatar} src={src} />
      }
    },
    {
      title: t('profile.product'),
      dataIndex: 'goodsName',
      key: 'goodsName',
      width: 200,
      render(val, item) {
        return getLangData(item.goodsNameI18n)
      }
    },
    {
      title: t('profile.price'),
      dataIndex: 'price',
      key: 'price',
      render(val, item) {
        return (
          <ColorfulText
            text={`${item.exchangePrice} ${t('gifr.pick')}`}
            colors={['#9A6BFE', '#FC78FF']}
            inline
            className={styles.price}
          />
        )
      }
    },
    {
      title: t('profile.operate'),
      dataIndex: 'operate',
      key: 'operate',
      align: 'center',
      width: 100,
      render(val, item) {
        return (
          <Space size={14}>
            <ProfileButton onClick={() => toDetail(item.id)}>
              {t('profile.details')}
            </ProfileButton>
          </Space>
        )
      }
    }
  ]

  return (
    <Page navigator={ITEMS_MANAGE}>
      <TitleModule title={t('profile.myItems')} more={false}>
        <Form form={form} onFinish={getNFT}>
          <div className={styles.search}>
            <Form.Item noStyle name="keyword">
              <LabelInput
                className={styles.input}
                label=""
                placeholder={t('profile.searchProduct')}
                inputExtra={
                  <img src={SearchIcon} className={styles.searchIcon} />
                }
              />
            </Form.Item>
            <Form.Item noStyle name="classId" initialValue={'0'}>
              <Select
                className={styles.select}
                options={[
                  { label: t('normal.all'), value: '0' },
                  ...classificationList.map((item) => {
                    return { label: item.className, value: item.id }
                  })
                ]}
              />
            </Form.Item>
            <ProfileButton
              className={styles.btn}
              type="submit"
              onClick={() => setPageNo(1)}
            >
              {t('dazzle.search')}
            </ProfileButton>
            <Switch
              open={open}
              onClick={() => setOpen((o) => !o)}
              className={styles.switch}
            />
          </div>
        </Form>
        <Empty data={nfts}>
          {open ? (
            <div className={styles.list}>
              {nfts.map((nft) => {
                return <Item key={nft.id} data={nft} />
              })}
            </div>
          ) : (
            <ProfileTable
              dataSource={nfts}
              columns={columns}
              pagination={false}
              rowKey="id"
            />
          )}
          <div className={styles.paginationCtn}>
            <Pagination
              total={total}
              showSizeChanger={false}
              onChange={(page) => setPageNo(page)}
              current={pageNo}
              pageSize={PAGE_SIZE}
            />
          </div>
        </Empty>
      </TitleModule>
    </Page>
  )
}

export default React.memo(NFT)
