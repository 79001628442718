import {
  getSessionItem,
  removeSessionItem,
  setSessionItem
} from '@/common/storage'
import { errorNotice } from '@/common/tip'
import { UserInfo, WalletApi } from '@/services/wallet'
import { create } from 'zustand'

type State = { info?: UserInfo; userAvatar?: string }

type Actions = {
  save: (qty: UserInfo) => void
  saveUserInfo: (qty: UserInfo) => void
  saveAvatar: (qty: string) => void
  clear: () => void
}

const initVal = () => {
  const info = getSessionItem('userInfo')

  if (info) return JSON.parse(info)

  return undefined
}
const avatar = () => {
  const avatar = getSessionItem('userAvatar')

  if (avatar) return avatar

  return undefined
}

const useUserInfo = create<State & Actions>((set) => ({
  info: initVal(),
  userAvatar: avatar(),
  save: async (info) => {
    if (info) {
      setSessionItem('token', info?.token)
      setSessionItem('userInfo', JSON.stringify(info))
    }

    if (!info) {
      return
    }

    set({ info })

    const res = await WalletApi.getUserInfo({
      accountId: info.accountId,
      chain: 'BNB'
    })
    if (res.data.code === 0) {
      if (res.data.data.avatar) {
        setSessionItem('userAvatar', res.data.data.avatar)
        set({ userAvatar: res.data.data.avatar })
      }
    } else {
      errorNotice(res.data.message)
    }
  },
  saveUserInfo: (info) => {
    if (info) {
      setSessionItem('token', info?.token)
      setSessionItem('userInfo', JSON.stringify(info))
    }
    set({ info })
  },
  saveAvatar: (userAvatar) => {
    if (userAvatar) {
      setSessionItem('userAvatar', userAvatar)
    }
    set({ userAvatar })
  },
  clear: () => {
    removeSessionItem('token')
    removeSessionItem('userInfo')
    removeSessionItem('userAvatar')
    set({ info: undefined, userAvatar: undefined })
  }
}))

export { useUserInfo }
