import { RecaptchaActions, getGoogleRecaptchaToken } from '@/common/login'
import { errorNotice, notice, showTip } from '@/common/tip'
import { VOTE_DATA } from '@/constants/vote'
import { useInviteCode } from '@/hooks/useInviteCode'
import { LoginWeb3Params, WalletApi } from '@/services/wallet'
import { useUserInfo } from '@/store/useUserInfo'
import { useEffect, useRef, useState } from 'react'
import { TA_EVENT_MAP, taSetAccountId, taTrack } from '@/common/thinkingdata'
import { setSessionItem } from '@/common/storage'

import {
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
  useSwitchChain,
  useChainId
} from 'wagmi'
import { WalletAddress } from '@/common/types'
import { useTranslation } from 'react-i18next'

const SIGN_MESSAGE = 'meet48'

type FetchParams = {
  address: WalletAddress
  coinCode: number
}

type Params = {
  onDismiss?: () => void
  openBindEmailModal: (loginParams: LoginWeb3Params) => void
}

const useWeb3Login = ({ onDismiss, openBindEmailModal }: Params) => {
  const { save } = useUserInfo()
  const walletType = useRef('')
  const currentConnect = useRef()
  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const { connectAsync, connectors } = useConnect()
  const metamaskConnector = connectors.find((c) => c.name === 'MetaMask')
  const okxConnector = connectors.find((c) => c.name === 'OKX Wallet')

  const chainId = useChainId()

  const { switchChainAsync } = useSwitchChain()

  const { disconnect } = useDisconnect()
  const { isConnected, address } = useAccount() // 帐户信息

  async function handleSwitchChain(address: WalletAddress, id: number) {
    await switchChainAsync({ chainId: id })
    if (address) {
      await fetch({ address, coinCode: id })
    }
  }
  const { inviteCode } = useInviteCode()

  const { signMessageAsync } = useSignMessage()

  function handleError(error: any) {
    errorNotice(error)
    setLoading(false)
    if (isConnected) {
      disconnect()
    }
  }

  const fetch = async (qs: FetchParams) => {
    const signature = await signMessageAsync({ message: SIGN_MESSAGE })
    const token = await getGoogleRecaptchaToken(RecaptchaActions.metamaskLogin)
    if (!token) {
      throw new Error('get token failed')
    }
    const params: LoginWeb3Params = {
      ...qs,
      message: SIGN_MESSAGE,
      signature: signature,
      timestamp: +new Date(),
      recommenderCode: inviteCode,
      googleRecaptchaToken: token,
      googleActionType: RecaptchaActions.metamaskLogin,
      loginType: walletType.current
    }
    const res = await WalletApi.mutateLoginWeb3(params)
    setLoading(false)

    if (res.data.code === 0) {
      notice(t('login.logSuccess'), 'success')
      taTrack(TA_EVENT_MAP.walletLoginSuccess)
      taSetAccountId(res.data.data.accountId)
      setSessionItem('login-time', `${Date.now()}`)
      save(res.data.data)
      onDismiss?.()
      return
    }
    if (res.data.code === 6032) {
      // 此时用户没有绑定邮箱
      openBindEmailModal(params)
      return
    }
    throw new Error(res.data.message)
  }

  const onConnect = async (connector: any, type: string) => {
    if (!connector) {
      return
    }
    currentConnect.current = connector
    walletType.current = type
    setLoading(true)
    try {
      if (isConnected) {
        if (chainId !== VOTE_DATA.chainId) {
          await handleSwitchChain(address!, VOTE_DATA.chainId)
          return
        }
        if (address) {
          await fetch({ address: address, coinCode: VOTE_DATA.chainId })
        }
        return
      }

      const res = await connectAsync({ connector })
      if (res.chainId !== VOTE_DATA.chainId) {
        await handleSwitchChain(res.accounts[0], VOTE_DATA.chainId)
        return
      }
      await fetch({ address: res.accounts[0], coinCode: res.chainId })
    } catch (error: any) {
      handleError(error)
    }
  }

  return {
    onConnect,
    metamaskConnector,
    okxConnector,
    loading,
    walletType
  }
}

export default useWeb3Login
