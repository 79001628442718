import { create } from 'zustand'
import { SafeVerificationParams } from '@/services/wallet'

export function noop() {
  return void 0
}

type PageData = {
  title: string
  back?: boolean
}

type Callback = (params: SafeVerificationParams) => any

type State = {
  callback: Callback
  pageData?: PageData
  params?: any
}

type Actions = {
  setCallback: (callback: Callback, pageData?: PageData) => void
  clearCallback: () => void
  setParams: (params: any) => void
  clearParams: () => void
}

const useSafeVerification = create<State & Actions>((set, get) => ({
  callback: noop,
  pageData: undefined,
  params: undefined,
  setCallback(callback: Callback, pageData?: PageData) {
    set({
      callback,
      pageData
    })
  },
  clearCallback() {
    set({
      callback: noop
    })
  },
  setParams(params) {
    set({ params })
  },
  clearParams() {
    set({ params: undefined })
  }
}))

export { useSafeVerification }
