import React, { useState, useMemo } from 'react'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import InputBox from '../../InputBox'
import { Button } from '@/components/Button'
import Divider from '@/components/Divider'
import { MetaMask, OKX } from '../../images'
import { ModalProps } from '@/components/Modal'
import useWeb3Login from '../hooks/useWeb3Login'
import { useUserInfo } from '@/store/useUserInfo'
import { openWindow, rsaEncrypt } from '@/utils/utils'
import { LoginWeb3Params, WalletApi } from '@/services/wallet'
import { ENTER_CODE, handleKeyDown } from '@/common/event'
import { destroyTip, errorNotice, notice, showTip } from '@/common/tip'
import useModal from '@/hooks/useModal'
import Register from '../../Register'
import { getLocalItem, setLocalItem, setSessionItem } from '@/common/storage'
import { TA_EVENT_MAP, taSetAccountId, taTrack } from '@/common/thinkingdata'
import { RecaptchaActions, getGoogleRecaptchaToken } from '@/common/login'
import SetEmailModal from '@/components/SetEmailModal'
import classNames from 'classnames'

type Props = ModalProps & {
  toForget: () => void
  className?: string
}

const LoginForm = ({ onDismiss, toForget, className = '' }: Props) => {
  const { t } = useTranslation()
  const [account, setAccount] = useState<string>(function () {
    return getLocalItem('last-login-account') ?? ''
  })
  const [pwd, setPwd] = useState('')

  const [openSetEmailModal] = useModal(<SetEmailModal />, {
    maskClosable: false
  })

  const [web2Loading, setWeb2Loading] = useState(false)

  const disabled = useMemo(() => {
    return account.trim().length === 0 || pwd.length === 0 || web2Loading
  }, [account, pwd, web2Loading])

  const { save } = useUserInfo()

  function handleOpenSetEmailModal(params: LoginWeb3Params) {
    openSetEmailModal({
      loginParams: params
    })
  }

  const { onConnect, metamaskConnector, okxConnector, loading, walletType } =
    useWeb3Login({
      onDismiss,
      openBindEmailModal: handleOpenSetEmailModal
    })

  const [openRegister] = useModal(<Register />)

  async function submit() {
    if (disabled) {
      return
    }
    try {
      setWeb2Loading(true)
      const token = await getGoogleRecaptchaToken(RecaptchaActions.login)
      if (!token) {
        setWeb2Loading(false)
        return
      }
      const res = await WalletApi.loginWeb2({
        identity: rsaEncrypt(account) as string,
        password: rsaEncrypt(pwd) as string,
        googleRecaptchaToken: token,
        googleActionType: RecaptchaActions.login
      })
      setWeb2Loading(false)
      if (res?.data.code === 0) {
        notice(t('login.logSuccess'), 'success')
        save(res.data.data)
        onDismiss?.()
        destroyTip()
        setLocalItem('last-login-account', account)
        setSessionItem('login-time', `${Date.now()}`)
        taSetAccountId(res.data.data.accountId)
      } else {
        showTip(res.data.message)
      }
      taTrack(TA_EVENT_MAP.login, { result: res.data.message })
    } catch (error: any) {
      errorNotice(error)
      setWeb2Loading(false)
    }
  }

  return (
    <div className={classNames(styles.loginForm, className)}>
      <div className={styles.title}>{t('normal.signIn')}</div>
      <InputBox
        title={t('login.loginAccountPlaceholder')}
        value={account}
        onChange={(v) => setAccount(v.trim())}
      />
      <InputBox
        title={t('login.password')}
        isPassword
        value={pwd}
        onChange={(v) => setPwd(v)}
        onKeyDown={handleKeyDown(ENTER_CODE, submit)}
        extra={
          <div className={styles.link} onClick={toForget}>
            {t('login.forget')}
          </div>
        }
      />
      <Button disabled={disabled} className={styles.btn} onClick={submit}>
        {t('login.login')}
      </Button>
      <div className={styles.registerCtn}>
        {t('login.notHaveAccount')}{' '}
        <div className={styles.link} onClick={openRegister}>
          {t('login.register')}
        </div>
      </div>
      <Divider className={styles.divider}>
        {t('login.signInWithMetamask')}
      </Divider>
      <div className={styles.web3BtnCtn}>
        <button
          disabled={loading}
          className={styles.web3Btn}
          onClick={() => {
            if (!window.ethereum || window.ethereum.domain === null) {
              openWindow(
                'https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=zh-CN&utm_source=ext_sidebar'
              )
              return
            }
            taTrack(TA_EVENT_MAP.walletLogin)
            onConnect(metamaskConnector, 'METAMASK')
          }}
        >
          <img src={MetaMask} className={styles.metamask} />
          <span>
            MetaMask
            {loading && walletType.current === 'METAMASK' && ' (connecting)'}
          </span>
        </button>
        <button
          disabled={loading}
          className={styles.web3Btn}
          onClick={() => {
            if (!window.okxwallet) {
              openWindow(
                'https://chromewebstore.google.com/detail/%E6%AC%A7%E6%98%93-web3-%E9%92%B1%E5%8C%85/mcohilncbfahbmgdjkbpemcciiolgcge?hl=zh-CN&utm_source=ext_sidebar'
              )
              return
            }
            taTrack(TA_EVENT_MAP.walletLogin)
            onConnect(okxConnector, 'OKX')
          }}
        >
          <img src={OKX} className={styles.metamask} />
          <span>
            OKX Wallet
            {loading && walletType.current === 'OKX' && ' (connecting)'}
          </span>
        </button>
      </div>
    </div>
  )
}

export default React.memo(LoginForm)
