import { create } from 'zustand'

type Fn = () => void

type State = { eventsMap: Record<string, Fn[]> }

type Actions = {
  registerEvent: (type: string, fn: Fn) => void
  execEvents: (type: string, clear?: boolean) => void
  clearEvents: (type: string) => void
  registerRouteChange: (fn: Fn) => void
  execRouteChangeEvent: () => any
  clearRouteChangeEvent: () => void
}

const useEvents = create<State & Actions>((set, get) => ({
  eventsMap: {},
  registerEvent(type, fn) {
    const map = get().eventsMap
    const events = map[type] ?? []
    events.push(fn)
    set((state) => {
      return {
        eventsMap: {
          ...state.eventsMap,
          [type]: events
        }
      }
    })
  },
  async execEvents(type, clear = false) {
    const map = get().eventsMap
    const events = map[type] ?? []
    const results = []
    for (let i = 0; i < events.length; i++) {
      const event = events[i]
      const data = await event()
      results.push(data)
    }
    if (clear) {
      set({
        eventsMap: {
          ...map,
          type: []
        }
      })
    }
    return results
  },
  clearEvents(type) {
    const map = get().eventsMap
    delete map[type]
    set(() => {
      return {
        eventsMap: map
      }
    })
  },
  registerRouteChange(fn: Fn) {
    const state = get()
    state.clearEvents('ROUTE_CHANGE')
    state.registerEvent('ROUTE_CHANGE', fn)
  },
  execRouteChangeEvent() {
    const state = get()
    const events = state.eventsMap['ROUTE_CHANGE'] ?? []
    if (events.length === 0) {
      return
    }
    const lastFn = events[events.length - 1]
    return lastFn()
  },
  clearRouteChangeEvent() {
    const state = get()
    state.clearEvents('ROUTE_CHANGE')
  }
}))

export { useEvents }

export const GLOBAL_CLICK_TYPE = 'GLOBAL_CLICK'

export const DAZZLE_DETAIL_LEAVE = 'DAZZLE_DETAIL_LEAVE'
