import { service } from '../http'
import { LatestVersionDownloadURLs } from './model'
import { PromiseResponse } from '../common'

const BASE_URL = '/common/api/v1'

export class CommonApi {
  static async getLatestVersionDownloadUrl(): PromiseResponse<LatestVersionDownloadURLs> {
    return service.get(`${BASE_URL}/appVersion/getLatestVersionDownloadUrl`)
  }
  // 上传文件
  static async fileUpload(file: File): PromiseResponse<string> {
    const formData = new FormData()
    formData.append('file', file)
    return service.post(`${BASE_URL}/file/upload`, formData, {
      headers: {
        isFormData: '1'
      }
    })
  }
}
