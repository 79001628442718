import React, { PropsWithChildren, useRef } from 'react'
import { Upload } from 'antd'
import { errorNotice, notice } from '@/common/tip'
import { useUserInfo } from '@/store/useUserInfo'
import type { RcFile, UploadProps } from 'antd/es/upload/interface'
import { getImageFileResolution } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import { WalletApi } from '@/services/wallet'
import styles from './style.module.less'

const AvatarUpload: React.FC<PropsWithChildren> = ({ children }) => {
  const imgType = useRef(1)
  const { userAvatar, info, saveAvatar } = useUserInfo()
  const { t } = useTranslation()

  const uploadType = userAvatar ? 1 : 0

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    imgType.current = file.type === 'image/jpeg' ? 1 : 2
    if (!isJpgOrPng) {
      errorNotice(t('personal.uploadFormat'))
      return Promise.reject()
    }
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) {
      errorNotice(t('personal.uploadSize'))
      return Promise.reject()
    }
    try {
      const { width, height } = await getImageFileResolution(file)
      if (width === 0) {
        return Promise.reject()
      }
      if (width > 300 || height > 300) {
        errorNotice(t('personal.uploadHint'))
        return Promise.reject()
      }
    } catch (error) {
      errorNotice(error)
    }
    return ''
  }

  const customRequest: UploadProps['customRequest'] = (option) => {
    const formData = new FormData()
    formData.append('files[]', option.file)
    const reader = new FileReader()
    reader.readAsDataURL(option.file as Blob)
    reader.onloadend = async (e: ProgressEvent<FileReader>) => {
      if (typeof e.target?.result === 'string') {
        const base64Image = e.target.result.replace(/^data.+?base64,/, '')
        const {
          data: { code, message, data }
        } = await WalletApi.uploadAvatar({
          accountId: info?.accountId ?? '',
          type: uploadType,
          base64Image
        })
        const { avatar } = data
        if (code === 0) {
          option.onSuccess?.(data)
          saveAvatar(avatar)
        } else {
          errorNotice(message)
        }
      }
    }
  }

  const changeHandle: UploadProps['onChange'] = (info) => {
    if (info.file.status === 'done') {
      const data = info.file.response
      if (data) {
        const text =
          uploadType === 1 ? 'personal.uploadSuccess' : 'personal.setAvatar'
        notice(t(text), 'success')
      } else {
        errorNotice(t('personal.uploadErr'))
      }
    } else if (info.file.status === 'error') {
      errorNotice(`${info.file.name}${t('personal.uploadErr')}`)
    }
  }

  return (
    <Upload
      name="file"
      accept="image/*"
      showUploadList={false}
      onChange={changeHandle}
      beforeUpload={beforeUpload}
      customRequest={customRequest}
    >
      <div className={styles.content}>{children}</div>
    </Upload>
  )
}

export default React.memo(AvatarUpload)
