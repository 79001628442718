import React from 'react'
import styles from './style.module.less'
import { Space } from 'antd'
import Checkbox from '@/components/Checkbox'
import {
  Address as AddressType,
  SafeVerificationParams,
  WalletApi
} from '@/services/wallet'
import { ellipsisText } from '@/utils/utils'
import { TextButton } from '@/components/Button'
import { useHistory } from 'react-router-dom'
import { useEvents } from '@/store/useEvents'
import { useSafeVerification } from '@/store/useSafeVerification'
import { errorNotice, notice } from '@/common/tip'
import { useTranslation } from 'react-i18next'

type Props = {
  operational?: boolean
  selectable?: boolean
  selected?: boolean
  data: AddressType
  onSelect?: (id: string) => void
}

const Address: React.FC<Props> = ({
  operational = true,
  data,
  selectable = true,
  selected,
  onSelect
}) => {
  const { t } = useTranslation()
  const { registerRouteChange } = useEvents()
  const { setCallback } = useSafeVerification()
  const history = useHistory()

  async function handleDelete(params: SafeVerificationParams) {
    const res = await WalletApi.deleteUserWithdrawAddress({
      id: [data.id],
      ...params
    })
    if (res.data.code === 0) {
      notice(t('wallet.deleteSuccess'), 'success')
      history.push('/profile/address-manage')
    } else {
      errorNotice(res.data.message)
    }
  }

  function handleEdit() {
    registerRouteChange(() => data)
    history.push(`/profile/add-address?id=${data.id}`)
  }

  return (
    <div className={styles.address}>
      <div className={styles.header}>
        <div className={styles.left}>
          {selectable && (
            <Checkbox
              onChange={() => onSelect?.(data.id)}
              checked={selected}
            ></Checkbox>
          )}
          <span className={styles.name}>{data.remark}</span>
        </div>
        {operational && (
          <Space>
            <TextButton
              onClick={() => {
                setCallback(handleDelete)
                history.push(`/profile/safety-verification?type=9`)
              }}
            >
              {t('wallet.address.delete')}
            </TextButton>
            <TextButton onClick={handleEdit}>
              {t('wallet.address.edit')}
            </TextButton>
          </Space>
        )}
      </div>
      <div className={styles.detail}>
        <div className={styles.line}>
          <span className={styles.label}>{t('wallet.currency')}</span>
          <span>{data.coinCode ?? '--'}</span>
        </div>
        <div className={styles.line}>
          <span className={styles.label}>
            {t('wallet.address.withdrawalNetwork')}
          </span>
          <span>{data.chainCode ?? '--'}</span>
        </div>
        <div className={styles.line}>
          <span className={styles.label}>
            {t('wallet.address.withdrawalAddress')}
          </span>
          <span>{ellipsisText(data.address)}</span>
        </div>
        <div className={styles.line}>
          <span className={styles.label}>
            {t('wallet.address.addressType')}
          </span>
          <span>{data.sourceCode}</span>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Address)
