import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import { ModalProps } from '@/components/Modal'
import { useTranslation } from 'react-i18next'
import InputBox from '../../InputBox'
import { Button } from '@/components/Button'
import useModal from '@/hooks/useModal'
import Login from '../../Login'
import { useInviteCode } from '@/hooks/useInviteCode'
import { destroyTip, showTip } from '@/common/tip'
import { checkEmail, rsaEncrypt } from '@/utils/utils'
import { WalletApi } from '@/services/wallet'
import { RegisterParams } from '..'
import { checkPassword, checkUsername } from '@/common/login'

type Props = ModalProps & {
  toVerifyCode: () => void
  className?: string
  onSaveParams: (params: RegisterParams) => void
  sendEmail: () => Promise<void>
}

const RegisterForm = ({
  toVerifyCode,
  className = '',
  onSaveParams,
  sendEmail
}: Props) => {
  const { t } = useTranslation()
  const [username, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [pwd, setPwd] = useState('')
  const [confirmPwd, setConfirmPwd] = useState('')

  const [openLogin] = useModal(<Login />)

  const { inviteCode } = useInviteCode()

  const disabled = useMemo(() => {
    return (
      username.length === 0 ||
      email.length === 0 ||
      pwd.length === 0 ||
      confirmPwd.length === 0
    )
  }, [username, email, pwd, confirmPwd])

  async function submit() {
    if (disabled) {
      return
    }
    if (!checkUsername(username)) {
      return
    }
    if (!checkEmail(email)) {
      return showTip(t('login.emailFormart'))
    }
    if (!checkPassword(pwd)) {
      return
    }
    if (pwd !== confirmPwd) {
      return showTip(t('login.pswNotSame'))
    }

    const param = {
      email: rsaEncrypt(email) as string
    }
    const res = await WalletApi.checkMailRegi(param)
    destroyTip()
    if (res.data.code === 0) {
      if (!res.data.data) {
        const params = {
          email,
          pwd,
          registrationType: 1,
          username,
          recommenderCode: inviteCode
        }
        onSaveParams(params)
        toVerifyCode()
        sendEmail()
      } else {
        showTip(t('login.hasRegi'))
      }
    } else {
      showTip(res.data.message)
    }
  }

  return (
    <div className={className}>
      <div className={styles.title}>{t('login.register')}</div>
      <InputBox
        title={t('login.username')}
        value={username}
        onChange={(v) => setUserName(v.trim())}
      />
      <InputBox
        title={t('login.inputEmail')}
        value={email}
        onChange={(v) => setEmail(v.trim())}
      />
      <InputBox
        title={t('login.password')}
        value={pwd}
        onChange={(v) => setPwd(v)}
        isPassword
      />
      <InputBox
        title={t('login.confirmPsw')}
        value={confirmPwd}
        onChange={(v) => setConfirmPwd(v)}
        isPassword
      />
      <Button disabled={disabled} className={styles.btn} onClick={submit}>
        {t('login.register')}
      </Button>
      <div className={styles.loginTip}>
        {t('login.alreadyHaveAccount')}{' '}
        <span className={styles.link} onClick={openLogin}>
          {t('normal.signIn')}
        </span>
      </div>
    </div>
  )
}

export default RegisterForm
