import React, { useState } from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type Props = {
  checked?: boolean
  onChange?: (v: boolean) => void
  className?: string
}

const StyledCheckbox: React.FC<Props> = ({ checked, onChange, className }) => {
  const hasCheckedProp = typeof checked === 'boolean'
  const [active, setActive] = useState<boolean>(
    hasCheckedProp ? checked : false
  )

  const isActive = hasCheckedProp ? checked : active

  function handleClick() {
    if (typeof checked === 'boolean') {
      onChange?.(!checked)
    } else {
      setActive(!active)
      onChange?.(!active)
    }
  }

  return (
    <div
      className={classNames(
        styles.checkbox,
        isActive && styles.checked,
        className
      )}
      onClick={handleClick}
    ></div>
  )
}

export default React.memo(StyledCheckbox)
