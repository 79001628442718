import React, { useRef } from 'react'
import styles from './style.module.less'
import CustomVideo from '@/components/CustomVideo'
import { HomemadeVideo } from '@/services/MediaAPI'
import { ModalProps } from '@/components/Modal'
import { CloseIconFilled } from '@/assets'

type Props = {
  videoInfo: HomemadeVideo
  refresh: () => void
} & ModalProps

const VideoModal: React.FC<Props> = ({ videoInfo, onDismiss, refresh }) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  function handleDelete() {
    onDismiss?.()
    refresh?.()
  }

  return (
    <div className={styles.videoModal}>
      <CustomVideo
        className={styles.video}
        videoRef={videoRef}
        isManage
        info={videoInfo}
        refresh={refresh}
        handleDelete={handleDelete}
      />
      <img
        src={CloseIconFilled}
        onClick={onDismiss}
        className={styles.closeIcon}
      />
    </div>
  )
}

export default React.memo(VideoModal)
