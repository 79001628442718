import React, { useState, useRef, useEffect } from 'react'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import { WalletApi } from '@/services/wallet'
import { errorNotice } from '@/common/tip'
import { checkEmailValidate } from '@/common/verify'
import LabelInput from './LabelInput'
import SearchInput from './SearchInput'
import PasswordInput from './PasswordInput'

const COUNT_START = 60

type CountdownInputProps = {
  value: string
  onChange: (v: string) => void
  placeholder?: string
  type: string
  duration?: number
  shouldExist?: boolean
}

const CountdownInput = (props: CountdownInputProps) => {
  const {
    value,
    placeholder = '',
    type,
    onChange,
    duration = COUNT_START,
    shouldExist = true
  } = props
  const { t } = useTranslation()
  const [time, setTime] = useState(duration)
  const timer = useRef<NodeJS.Timer>()

  const sendEmail = async () => {
    const validate = await checkEmailValidate(value, shouldExist)
    if (!validate) {
      return
    }
    setTime((t) => t - 1)
    timer.current = setInterval(() => {
      setTime((t) => t - 1)
    }, 1000)
    const codeRes = await WalletApi.getCodeFromEmail(value, type)
    if (codeRes.data.code !== 0) {
      errorNotice(codeRes.data.message)
    }
  }

  useEffect(() => {
    if (time === 0) {
      clearInterval(timer.current)
      setTime(duration)
    }
  }, [time])

  useEffect(() => {
    return () => clearInterval(timer.current)
  }, [])

  return (
    <div className={styles.inputBox}>
      <input
        className={styles.input}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {time < 60 ? (
        <span className={styles.sent}>
          {t('personal.sent')}({time})
        </span>
      ) : (
        <span className={styles.send} onClick={sendEmail}>
          {t('personal.sendCode')}
        </span>
      )}
    </div>
  )
}

type SubmitInputProps = {
  value: string
  onChange: (v: string) => void
  onSubmit: () => void
  placeholder?: string
  inputType?: string
}

const SubmitInput = (props: SubmitInputProps) => {
  const {
    value,
    onChange,
    onSubmit,
    placeholder = '',
    inputType = 'text'
  } = props
  const { t } = useTranslation()
  return (
    <div className={styles.inputBox}>
      <input
        className={styles.input}
        type={inputType}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <span onClick={onSubmit} className={styles.changeBtn}>
        {t('personal.verifyBtn')}
      </span>
    </div>
  )
}

export default {
  CountdownInput: React.memo(CountdownInput),
  SubmitInput: React.memo(SubmitInput),
  LabelInput,
  SearchInput,
  PasswordInput
}
