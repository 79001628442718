import { getSessionItem, setSessionItem } from '@/common/storage'
import { useCallback, useMemo } from 'react'

export function useInviteCode() {
  const inviteCode = useMemo(() => {
    return getSessionItem('inviteCode') || ''
  }, [])

  const initInviteCode = useCallback(() => {
    // 解析并邀请码
    const match = new RegExp(`[?&]invite=([^&]*)`).exec(window.location.href)
    const inviteCode = match && decodeURIComponent(match[1].replace(/\+/g, ' '))

    if (inviteCode) setSessionItem('inviteCode', inviteCode ?? '')
  }, [])

  return { initInviteCode, inviteCode }
}
