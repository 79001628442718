import { service } from '../http'
import {
  PageListParams,
  HomemadeVideo,
  HomemadeDetail,
  SubmitVideoParams,
  CompleteParams,
  PathToken,
  onProgress,
  EncryptData,
  GetMyPageListParams,
  Tag
} from './model'
import { PromiseListResponse, PromiseResponse, replaceToV2 } from '../common'
import { errorNotice } from '@/common/tip'
import { decryptData } from '@/common/dataConvert'
import i18next from 'i18next'
import { TA_EVENT_MAP, taTrack } from '@/common/thinkingdata'

const BASE_URL = '/media/api/v1'

// 上传到google服务器
export async function uploadGoogleFile(
  url: string,
  file: File,
  onProgress?: onProgress
): Promise<void> {
  return new Promise(function (resolve, reject) {
    function responseHandle(xhr: XMLHttpRequest) {
      const isSuccess = xhr.status === 200
      const reason = isSuccess
        ? ''
        : xhr.status === 0
          ? i18next.t('dazzle.uploadFileErrorTip')
          : `unknown error. status: ${xhr.status}`
      taTrack(TA_EVENT_MAP.videoUploadResult, {
        device_type: 'WEB',
        upload_status: isSuccess,
        fail_reason: reason
      })
      if (xhr.status === 200) {
        resolve()
      } else if (xhr.status === 0) {
        // 出现过一个情况，不开梯子google那边拒绝请求，这时status===0
        reject(i18next.t('dazzle.uploadFileErrorTip'))
      } else {
        reject(`File upload failed, status: ${xhr.status}`)
      }
    }
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', url, true)
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        responseHandle(xhr)
      }
    }
    xhr.upload.addEventListener('progress', function (e) {
      if (e.lengthComputable) {
        const percentComplete = (e.loaded / e.total) * 100
        onProgress?.(percentComplete)
      }
    })
    xhr.onerror = function (e: any) {
      responseHandle(e.target)
    }
    xhr.send(file)
  })
}

export class MediaAPI {
  // 获取自制视频列表
  static async getHomeMadeVideos(
    params: PageListParams
  ): PromiseListResponse<{ records: HomemadeVideo[] }> {
    return service.get(replaceToV2(`${BASE_URL}/homemadeVideo/pageList`), {
      params
    })
  }
  // 获取自制视频详情
  static async getHomemadeVideoDetail(
    id: string
  ): PromiseResponse<HomemadeDetail> {
    return service.post(
      `${BASE_URL}/homemadeVideo/getDetail`,
      { id },
      {
        headers: {
          needEncrypt: '1'
        }
      }
    )
  }
  // 获取自制视频详情
  static async getRecommendList(
    currentId: string
  ): PromiseResponse<HomemadeVideo[]> {
    return service.get(`${BASE_URL}/homemadeVideo/recommendList`, {
      params: { currentId }
    })
  }
  // 上传视频
  static async submitVideoForm(
    params: SubmitVideoParams
  ): PromiseResponse<HomemadeVideo[]> {
    return service.post(`${BASE_URL}/homemadeVideo/submitVideoForm`, params, {
      headers: {
        needEncrypt: '1'
      }
    })
  }
  // 完播视频
  static async complete(params: CompleteParams): PromiseResponse<EncryptData> {
    return service.post(`${BASE_URL}/homemadeVideo/complete`, params, {
      headers: {
        needEncrypt: '1'
      }
    })
  }
  static async getPathToken(fileName: string): PromiseResponse<PathToken> {
    return service.post(
      `${BASE_URL}/homemadeVideo/getPathToken`,
      { fileName },
      {
        headers: {
          needEncrypt: '1'
        }
      }
    )
  }
  static async uploadFile(file: File, onProgress?: onProgress) {
    try {
      const res = await this.getPathToken(file.name)
      if (res.data.code !== 0) {
        errorNotice(res.data.message)
        return
      }
      const data = decryptData(res.data.data)
      const { filePath, pathToken } = data
      await uploadGoogleFile(pathToken, file, onProgress)
      return filePath
    } catch (error: any) {
      errorNotice(error)
      return ''
    }
  }
  // 获取视频管理列表
  static async getMyPageList(
    params: GetMyPageListParams
  ): PromiseListResponse<{ records: HomemadeVideo[] }> {
    return service.get(replaceToV2(`${BASE_URL}/homemadeVideo/myPageList`), {
      params
    })
  }
  // 获取作者创作奖励积分数
  static async getCreatorTotalPoints(): PromiseResponse<{
    totalPoints: string
  }> {
    return service.get(`${BASE_URL}/homemadeVideo/creatorTotalPoints`)
  }
  // 视频操作 - release上架，takeDown下架，delete删除
  static async operateVideo(
    videoId: string,
    operate: string
  ): PromiseResponse<void> {
    return service.post(
      `${BASE_URL}/homemadeVideo/operate`,
      { operate, videoId },
      {
        headers: {
          needEncrypt: '1'
        }
      }
    )
  }
  // 获取作者创作奖励积分数
  static async getVideoDurationLimitation(): PromiseResponse<{
    minDuration: number
  }> {
    return service.get(`${BASE_URL}/homemadeVideo/getVideoDurationLimitation`)
  }
  // 获取dazzle的tag列表
  static async getTagList(): PromiseResponse<{
    list: Tag[]
  }> {
    return service.get(`${BASE_URL}/homemadeVideo/tagList`)
  }
  // 获取dazzle的tag列表，没有all选项
  static async getTagListWithoutAll(): PromiseResponse<{
    list: Tag[]
  }> {
    return service.get(`${BASE_URL}/homemadeVideo/tagListWithOutAll`)
  }
}
