import React from 'react'
import styles from './style.module.less'

type Props = {
  label: string
  desc: string
}

const Description: React.FC<Props> = ({ label, desc }) => {
  return (
    <div className={styles.description}>
      <div>{label}</div>
      <div className={styles.desc}>{desc}</div>
    </div>
  )
}

export default React.memo(Description)
