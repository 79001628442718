import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import ModalWrapper from '../ModalWrapper'
import { useTranslation } from 'react-i18next'
import InputBox from '@/components/InputBox'
import { Button, TextButton } from '../Button'
import useSendEmail from '@/hooks/useSendEmail'
import { LoginWeb3Params, WalletApi } from '@/services/wallet'
import { rsaEncrypt } from '@/utils/utils'
import { useUserInfo } from '@/store/useUserInfo'
import { errorNotice, notice } from '@/common/tip'
import { ModalProps } from '../Modal'
import { TA_EVENT_MAP, taSetAccountId, taTrack } from '@/common/thinkingdata'
import { setSessionItem } from '@/common/storage'
import classNames from 'classnames'

const { LabelInput } = InputBox

type Props = {
  loginParams?: LoginWeb3Params
} & ModalProps

const SetEmailModal: React.FC<Props> = ({ onDismiss, loginParams }) => {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const { save } = useUserInfo()

  const { sendEmail, sendAble, sendText } = useSendEmail({ type: '4' })

  const disabled = useMemo(() => {
    return email.length === 0 || code.length === 0
  }, [code, email])

  async function onSubmit() {
    if (!loginParams) {
      errorNotice('login info missing')
      return
    }
    const res = await WalletApi.mutateLoginWeb3({
      ...loginParams,
      mail: rsaEncrypt(email) as string,
      code
    })
    if (res.data.code == 0) {
      notice(t('login.logSuccess'), 'success')
      taTrack(TA_EVENT_MAP.walletLoginSuccess)
      taSetAccountId(res.data.data.accountId)
      setSessionItem('login-time', `${Date.now()}`)
      save(res.data.data)
      onDismiss?.()
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <ModalWrapper
      title={t('systemNotice.setEmailTip')}
      width={440}
      hasClose={false}
    >
      <div className={styles.ctn}>
        <LabelInput
          label={t('normal.email')}
          value={email}
          onInput={(e: any) => setEmail(e.target.value)}
        />
        <LabelInput
          value={code}
          onInput={(e: any) => setCode(e.target.value)}
          label={t('normal.verificationCode')}
          inputExtra={
            <TextButton
              className={classNames(sendAble && styles.send)}
              onClick={() => sendEmail(email, false)}
              disabled={!sendAble}
            >
              {sendText}
            </TextButton>
          }
        />
        <Button disabled={disabled} onClick={onSubmit}>
          {t('normal.ok')}
        </Button>
      </div>
    </ModalWrapper>
  )
}

export default React.memo(SetEmailModal)
