import { service } from '../http'
import { SystemNotice } from './model'
import { PromiseResponse } from '../common'

const BASE_URL = '/message/api/v1'

export class MessageApi {
  static async getSystemNotice(type = 1): PromiseResponse<SystemNotice> {
    return service.post(`${BASE_URL}/notice/getSystemNotice`, {
      type
    })
  }
}
