import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import ModalWrapper from '@/components/ModalWrapper'
import { ModalProps } from '@/components/Modal'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import { getQrCode } from '@/utils/utils'
import TextCopy from '@/components/TextCopy'
import { useTranslation } from 'react-i18next'

const TransferModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const [qrCode, setQrCode] = useState<string>()
  const { data } = useQuery(WalletApi.getNFTDepositAddress)

  useEffect(() => {
    if (data?.address) {
      getQrCode(data.address).then((src) => setQrCode(src))
    }
  }, [data])

  return (
    <ModalWrapper
      title={t('wallet.nft.depositNFT')}
      onDismiss={onDismiss}
      width={471}
    >
      <div className={styles.wrapper}>
        <img src={qrCode} className={styles.qrCode} />
        <div>{t('wallet.nft.bscDepositAddress')}</div>
        <TextCopy text={data?.address ?? ''}>{data?.address}</TextCopy>
      </div>
      <div className={styles.tip}>{t('wallet.nft.bscDepositTip')}</div>
    </ModalWrapper>
  )
}

export default React.memo(TransferModal)
