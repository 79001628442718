import React from 'react'
import styles from './style.module.less'
import { LoginPoster } from './images'
import { CloseIconFilled } from '@/assets'
import { ModalProps } from '../Modal'

const Wrapper = ({
  children,
  onDismiss
}: React.PropsWithChildren<ModalProps>) => {
  return (
    <div className={styles.loginModal}>
      <img src={CloseIconFilled} className={styles.close} onClick={onDismiss} />
      <img src={LoginPoster} className={styles.poster} />
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default Wrapper
