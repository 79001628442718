import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import Dropdown from '@/components/Dropdown'
import { DownArrow } from '@/assets'
import classNames from 'classnames'
import InputBox from '@/components/InputBox'
import { CurrencyIcon } from '../../image'
import { SupportCoin, WalletApi } from '@/services/wallet'
import { useTranslation } from 'react-i18next'
import { errorNotice } from '@/common/tip'

const { SearchInput } = InputBox

type Props = {
  label?: string
  className?: string
  placeholder?: string
  value?: SupportCoin
  onChange?: (val: SupportCoin) => void
  type?: 'Withdraw' | 'Deposit'
  coinCode?: string
}

type FormProps = {
  onChange?: (val: SupportCoin) => void
  close: () => void
  list: SupportCoin[]
}

type ItemProps = {
  data: SupportCoin
  onClick?: (data: SupportCoin) => void
}

function Item({ data, onClick }: ItemProps) {
  return (
    <div className={styles.line} onClick={() => onClick?.(data)}>
      <img src={data.coinImg ?? CurrencyIcon} className={styles.currencyIcon} />
      <div className={styles.texts}>
        <span className={styles.text}>{data.coinCode}</span>
        <span>{data.coinName}</span>
      </div>
    </div>
  )
}

function Form({ onChange, close, list }: FormProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState('')

  function handleSelect(item: SupportCoin) {
    onChange?.(item)
    close()
  }

  const filterList = useMemo(() => {
    const reg = new RegExp(value, 'i')
    return list.filter((item) => reg.test(item.coinCode))
  }, [list, value])

  return (
    <div className={styles.form}>
      <div className={styles.item}>
        <div className={styles.label}>{t('wallet.address.selectCoin')}</div>
        <SearchInput
          placeholder={t('normal.search')}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className={styles.item}>
        {/* <div className={styles.label}>币种列表</div> */}
        <div className={styles.list}>
          {filterList.map((item) => {
            return <Item key={item.id} data={item} onClick={handleSelect} />
          })}
        </div>
      </div>
    </div>
  )
}

const CurrencySelect: React.FC<Props> = ({
  label,
  className,
  placeholder = 'wallet.address.selectCoin',
  value,
  onChange,
  type,
  coinCode
}) => {
  const [list, setList] = useState<SupportCoin[]>([])
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  function close() {
    setOpen(false)
  }

  const text = useMemo(() => {
    return value ? <Item data={value} /> : t(placeholder)
  }, [list, value])

  useEffect(() => {
    async function fetch() {
      const res = await WalletApi.getCoinSupportList(type)
      if (res.data.code === 0) {
        setList(res.data.data)
        const target = res.data.data.find((item) => item.coinCode === coinCode)
        if (target) {
          onChange?.(target)
        }
      } else {
        errorNotice(res.data.message)
      }
    }
    fetch()
  }, [])

  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <Dropdown
        onOpenChange={(open) => setOpen(open)}
        trigger={['click']}
        open={open}
        dropdownRender={() => {
          return <Form onChange={onChange} close={close} list={list} />
        }}
      >
        <div className={styles.select} onClick={() => setOpen((o) => !o)}>
          <div className={value ? styles.selected : ''}>{text}</div>
          <img
            src={DownArrow}
            className={classNames(styles.arrow, open && styles.openedArrow)}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default React.memo(CurrencySelect)
