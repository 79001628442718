import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import { ORDER, POINT } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import Select from '@/components/Select'
import { StyledRangePicker } from '@/components/DatePicker'
import { Form, Space, TableProps } from 'antd'
import { ProfileButton } from '@/components/Button'
import { ProfileTable } from '@/components/Table'
import { useOrderOptions } from '@/store/useOrderOptions'
import { formatTime, getLangData } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import { ExchangeRecord, ShopAPI } from '@/services/shopAPI'
import { errorNotice } from '@/common/tip'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import { getDayEnd, getDayStart } from '@/common/profile'

const CLASS_TYPE = 1
const PAGE_SIZE = 10

const PointRecord: React.FC = () => {
  const { t } = useTranslation()
  const { allOptions, getOptions, getOptionByType } = useOrderOptions()
  const { i18n } = useTranslation()
  const [dataSource, setDataSource] = useState<ExchangeRecord[]>([])
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    if (allOptions.length === 0) {
      getOptions()
    }
  }, [allOptions.length])

  const options = useMemo(() => {
    return getOptionByType(CLASS_TYPE).map((item) => ({
      label: getLangData(item.orderSearchNameI18n),
      value: item.orderSearchType
    }))
  }, [allOptions, i18n.language])

  async function getList() {
    const { range, searchType } = form.getFieldsValue()
    const startTime = getDayStart(range?.[0]?.valueOf())
    const endTime = getDayEnd(range?.[1]?.valueOf())
    const res = await ShopAPI.getExchangeRecords<ExchangeRecord>({
      pageNo,
      pageSize: PAGE_SIZE,
      classType: CLASS_TYPE,
      searchType,
      startTime,
      endTime
    })
    if (res.data.code === 0) {
      setDataSource(res.data.data.records)
      setTotal(+res.data.data.total)
    } else {
      errorNotice(res.data.message)
    }
  }

  useEffect(() => {
    getList()
  }, [pageNo])

  const columns: TableProps<ExchangeRecord>['columns'] = [
    {
      title: t('profile.resource'),
      dataIndex: 'pointDetailsI18n',
      key: 'pointDetailsI18n',
      width: 250,
      render(val, item) {
        return getLangData(item.pointDetailsI18n)
      }
    },
    {
      title: t('profile.time'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      width: 250,
      render(val, item) {
        return formatTime(+item.createdTime)
      }
    },
    {
      title: t('profile.status'),
      dataIndex: 'changeNameI18n',
      key: 'changeNameI18n',
      width: 150,
      render(val, item) {
        return getLangData(item.changeNameI18n)
      }
    },
    {
      title: t('profile.amount'),
      dataIndex: 'amount',
      key: 'amount',
      render(val, item) {
        return Math.abs(item.amount)
      }
    }
  ]

  function handleReset() {
    form.resetFields()
  }

  return (
    <Page navigator={`${ORDER}/${POINT}`}>
      <TitleModule
        title={t('profile.pointRecord')}
        more={false}
        className={styles.module}
      >
        <div className={styles.search}>
          <Form form={form} onFinish={getList}>
            <Space size={12}>
              <Form.Item noStyle name="range">
                <StyledRangePicker
                  className={styles.formItem}
                  placeholder={[t('profile.startTime'), t('profile.endTime')]}
                  allowEmpty={[true, true]}
                />
              </Form.Item>
              <Form.Item noStyle name="searchType" initialValue={0}>
                <Select className={styles.formItem} options={options} />
              </Form.Item>
              <ProfileButton
                className={styles.btn}
                type="submit"
                onClick={() => setPageNo(1)}
              >
                {t('dazzle.search')}
              </ProfileButton>
              <ProfileButton className={styles.btn} onClick={handleReset}>
                {t('profile.reset')}
              </ProfileButton>
            </Space>
          </Form>
        </div>
        <div className={styles.tableCtn}>
          <Empty data={dataSource}>
            <ProfileTable
              dataSource={dataSource}
              columns={columns}
              pagination={false}
              rowKey="id"
            />
            <div className={styles.paginationCtn}>
              <Pagination
                total={total}
                showSizeChanger={false}
                onChange={(page) => setPageNo(page)}
                current={pageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        </div>
      </TitleModule>
    </Page>
  )
}

export default React.memo(PointRecord)
