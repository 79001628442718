import React from 'react'
import styles from './style.module.less'
import { NoDataDark, NoDataLight } from '@/assets'
import classNames from 'classnames'

type NoDataProps = {
  text?: string
  className?: string
  theme?: 'dark' | 'light'
}

const NoData = ({
  text = 'no data',
  className = '',
  theme = 'dark'
}: NoDataProps) => {
  return (
    <div
      className={classNames(
        styles.noData,
        theme === 'dark' && styles.dark,
        theme === 'light' && styles.light,
        className
      )}
    >
      <img
        src={theme === 'dark' ? NoDataDark : NoDataLight}
        className={styles.noDataImg}
      />
      <div>{text}</div>
    </div>
  )
}

export default NoData
