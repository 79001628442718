import React from 'react'
import styles from './style.module.less'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import classNames from 'classnames'

type Props = TabsProps & {
  className?: string
}

const StyledTabs: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <div className={classNames(styles.tabs, className)}>
      <Tabs {...rest} />
    </div>
  )
}

export default React.memo(StyledTabs)
