import React from 'react'
import styles from './style.module.less'
import { CheckedIconPick } from '@/assets'

type Props = {
  checked?: boolean
}

const Radio: React.FC<Props> = ({ checked }) => {
  return checked ? (
    <img src={CheckedIconPick} className={styles.checked} />
  ) : (
    <div className={styles.unselected}></div>
  )
}

export default React.memo(Radio)
