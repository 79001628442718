import i18next from 'i18next'
import { notice, showTip } from './tip'
import { checkEmail } from './verify'
import { getStringLength } from '@/utils/utils'
import { GOOGLE_RECAPTCHA_KEY } from '@/config'
import { RecaptchaAction, WalletApi } from '@/services/wallet'

const getEmailValid = (email: string) => {
  if (!email) {
    showTip(i18next.t('login.email'))
    return false
  }
  if (!checkEmail(email)) {
    showTip(i18next.t('login.emailFormart'))
    return false
  }
  return true
}
// tipType为提示类型，1为自定义提示（登录注册弹框使用），2为个人中心
const tipHandle = (text: string, tipType: number) => {
  if (tipType === 1) {
    showTip(text)
  } else if (tipType === 2) {
    notice(text)
  }
}

const checkPassword = (password: string, tipType = 1) => {
  const pwdReg =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_\-+={}[\]|\\:;"'<>,.?/~`©®™✓\s])[A-Za-z\d!@#$%^&*()_\-+={}[\]|\\:;"'<>,.?/~`©®™✓\s]{6,14}$/
  if (!pwdReg.test(password)) {
    tipHandle(i18next.t('login.passwordInvalid'), tipType)
    return false
  }
  return true
}

// tipType为提示类型，1为自定义提示（登录注册弹框使用），2为个人中心
const checkUsername = (username: string, tipType = 1) => {
  const lastLen = getStringLength(username)
  if (lastLen < 4 || lastLen > 20) {
    tipHandle(i18next.t('login.nameForErr'), tipType)
    return false
  }
  return true
}

async function getToken(action: RecaptchaAction) {
  const token = await window.grecaptcha.enterprise.execute(
    GOOGLE_RECAPTCHA_KEY,
    {
      action
    }
  )
  return token
}

async function getGoogleRecaptchaToken(
  action: RecaptchaAction
): Promise<string> {
  const res = await WalletApi.checkArea()
  if (res.data.code !== 0) {
    showTip(res.data.message)
    return ''
  }
  return new Promise(function (resolve, reject) {
    if (!window.grecaptcha) {
      reject(i18next.t('login.loadGoogleRecaptchaFail'))
    }
    let executeTime = 0
    async function exec() {
      try {
        executeTime++
        const token = await getToken(action)
        resolve(token)
      } catch (error: any) {
        if (executeTime < 4) {
          exec()
        } else {
          reject('Fail to get google recaptcha token')
        }
      }
    }
    window.grecaptcha.enterprise.ready(exec)
  })
}

export const RecaptchaActions: Record<string, RecaptchaAction> = {
  login: 'login',
  register: 'register',
  metamaskLogin: 'metaMaskLogin',
  jwtAuthLogin: 'jwtAuthLogin',
  sendEmail: 'sendEmail'
}

export { getEmailValid, checkPassword, checkUsername, getGoogleRecaptchaToken }
