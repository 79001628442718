import React from 'react'
import styles from './style.module.less'
import { Dropdown as AntdDropdown, DropDownProps } from 'antd'

const Dropdown: React.FC<DropDownProps> = ({ children, ...rest }) => {
  return (
    <AntdDropdown {...rest} overlayClassName={styles.dropdown}>
      {children}
    </AntdDropdown>
  )
}

export default React.memo(Dropdown)
