import React from 'react'
import styles from './style.module.less'
import { ProfileTable } from '@/components/Table'
import { DepositRecord } from '@/services/wallet'
import { TableProps } from 'antd'
import { formatTime } from '@/utils/utils'
import { getTableCopyText } from '@/common/profile'
import { useTranslation } from 'react-i18next'

type Props = {
  dataSource: DepositRecord[]
  hideOrderId?: boolean
}

const statusMap: Record<string, string> = {
  1: 'wallet.success',
  2: 'wallet.underReview',
  3: 'wallet.pending',
  4: 'wallet.rejected'
}

const RechargeTable: React.FC<Props> = ({
  dataSource,
  hideOrderId = false
}) => {
  const { t } = useTranslation()

  const columns: TableProps<DepositRecord>['columns'] = [
    {
      title: t('normal.amount') as string,
      dataIndex: 'amount',
      key: 'amount',
      align: 'left',
      width: 120,
      render(val, record) {
        return (
          <div className={styles.amount}>
            <img src={record.coinImg} className={styles.currencyIcon} />
            <span className={styles.kind}>
              {val} {record.coinCode}
            </span>
          </div>
        )
      }
    },
    {
      title: t('normal.status') as string,
      dataIndex: 'orderStatus',
      key: 'orderStatus',
      align: 'center',
      width: 100,
      render(val, record) {
        const text = statusMap[record.orderStatus]
        return text ? t(text) : '--'
      }
    },
    {
      title: t('normal.time') as string,
      dataIndex: 'orderCreateTime',
      key: 'orderCreateTime',
      align: 'center',
      width: 120,
      render(val, record) {
        return formatTime(+record.orderCreateTime)
      }
    },
    {
      title: t('normal.network') as string,
      dataIndex: 'chainCode',
      key: 'chainCode',
      align: 'center'
    },
    {
      title: t('wallet.from') as string,
      dataIndex: 'fromAddress',
      key: 'fromAddress',
      align: 'center',
      render(val, record) {
        return record.fromAddress
          ? getTableCopyText(record.fromAddress, true, record.fromAddressUrl)
          : getTableCopyText(record.otherSideUserCode, false)
      }
    },
    {
      title: 'TXID',
      dataIndex: 'txHash',
      key: 'txHash',
      align: 'center',
      render(val, record) {
        return getTableCopyText(record.txHash)
      }
    },
    {
      title: t('normal.orderId') as string,
      dataIndex: 'txId',
      key: 'txId',
      align: 'center',
      render(val, record) {
        return getTableCopyText(record.txId, false)
      }
    }
  ]

  const c = hideOrderId
    ? columns.filter((column) => column.key !== 'txId')
    : columns

  return (
    <ProfileTable
      rowKey={'txId'}
      dataSource={dataSource}
      columns={c}
      pagination={false}
    />
  )
}

export default React.memo(RechargeTable)
