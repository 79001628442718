export const PERSONAL = 'personal'
export const ASSETS = 'assets'
export const NFT_MANAGE = 'nft-manage'
export const ITEMS_MANAGE = 'items-manage'
export const ORDER = 'order'
export const WITHDRAW = 'withdraw'
export const POINT = 'point'
export const ORDER_NFT = 'order-nft'
export const OTHER = 'other'
export const SETTING = 'setting'
export const WITHDRAW_MANAGE = 'withdrawManage'
export const WITHDRAW_ADD_ADDRESS = 'withdrawAddAddress'
export const DAZZLE_MANAGE = 'dazzleManage'
export const NFT_TRANSFER_RECORDS = 'nft-transfer-records'

export const MODULES = [
  {
    name: 'personal.personal',
    key: PERSONAL,
    path: '/personal'
  },
  {
    name: 'normal.asset',
    key: ASSETS,
    path: '/profile/assets'
  },
  {
    name: 'dazzle.myCreations',
    key: DAZZLE_MANAGE,
    path: '/profile/video-manage'
  },
  {
    name: 'profile.myNFT',
    key: NFT_MANAGE,
    path: '/profile/nft'
  },
  {
    name: 'profile.myItems',
    key: ITEMS_MANAGE,
    path: '/profile/items'
  },
  {
    name: 'profile.orderRecord',
    key: ORDER,
    path: '/profile/currency-record',
    children: [
      {
        name: 'wallet.depositAndWithdrawalRecords',
        key: WITHDRAW,
        path: '/profile/currency-record'
      },
      {
        name: 'profile.pointRecord',
        key: POINT,
        path: '/profile/point-record'
      },
      {
        name: 'profile.nftRecord',
        key: ORDER_NFT,
        path: '/profile/nft-record'
      },
      {
        name: 'profile.otherRecord',
        key: OTHER,
        path: '/profile/other-record'
      },
      {
        name: 'profile.nftTransferRecord',
        key: NFT_TRANSFER_RECORDS,
        path: '/profile/nft-transfer-record'
      }
    ]
  },
  {
    name: 'profile.setting',
    key: SETTING,
    path: '/profile/setting'
  }
]
