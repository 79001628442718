import React from 'react'
import styles from './style.module.less'
import { PlayIconOutlined } from '@/assets'
import { HomemadeVideo } from '@/services/MediaAPI'
import { getMediaSrc } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import { getStatus, getStatusText } from '@/common/dazzle'
import ImgPreview from '@/components/ImgPreview'

type Props = {
  info: HomemadeVideo
  onClick: (videoInfo: HomemadeVideo) => void
}

const VideoPreview: React.FC<Props> = ({ info, onClick }) => {
  const { t } = useTranslation()
  const { title, coverImageUrl } = info
  const src = getMediaSrc(coverImageUrl)
  const status = getStatus(info)

  const showPlayAmount = status === 1 || status === 3

  return (
    <div className={styles.videoPreview} onClick={() => onClick(info)}>
      <ImgPreview src={src} className={styles.imgCtn}>
        <div className={styles.videoInfo}>
          <div className={styles.left}>
            {showPlayAmount && (
              <div className={styles.space}>
                <img src={PlayIconOutlined} className={styles.playIcon} />
                <span>{info.playCount || 0}</span>
              </div>
            )}
          </div>
          <div className={styles.videoStatus}>{t(getStatusText(info))}</div>
        </div>
      </ImgPreview>
      <div className={styles.title}>{title}</div>
    </div>
  )
}

export default React.memo(VideoPreview)
