// 数数-埋点

import { THINKING_DATA_APP_ID, THINKING_DATA_SERVER_URL } from '@/config'
import thinkingdata from 'thinkingdata-browser'
import { getSessionItem, removeSessionItem } from './storage'

type Properties = Record<string, any>

export const TA_EVENT_MAP = {
  register: 'register_web',
  login: 'login_web',
  logout: 'logout_web',
  walletLogin: 'login_wallet_web',
  walletLoginSuccess: 'login_wallet_success_web',
  enterPage: 'enter_page_web',
  enterSocialMedia: 'enter_socialmedia_web',
  completeTask: 'task_complete_web',
  voteSuccessGifr: 'vote_sucess_gifr',
  videoUpload: 'video_upload',
  videoUploadResult: 'video_upload_result'
}

const config = {
  showLog: false,
  appId: THINKING_DATA_APP_ID,
  serverUrl: THINKING_DATA_SERVER_URL,
  autoTrack: {
    pageShow: true, //开启页面展示事件，事件名ta_page_show
    pageHide: true //开启页面隐藏事件，事件名ta_page_hide
  }
}

export function taInit() {
  thinkingdata.init(config)
}

export function taSetAccountId(accountId: string) {
  if (!accountId) {
    console.log('accountId 不存在')
    return
  }
  thinkingdata.login(accountId)
}

export function taClearAccountId() {
  thinkingdata.logout()
}

export function taSetSuperProperties(superProperties: Properties) {
  thinkingdata.setSuperProperties(superProperties)
}

export function taTrack(event: string, props?: Properties) {
  thinkingdata.track(event, props)
}

export function taUserSet(props: Properties) {
  thinkingdata.userSet(props)
}

export function taGetDistinctId() {
  return thinkingdata.getDistinctId()
}

export function taEnterPageTrack(
  nextPage: string,
  entrance = '',
  subPage = ''
) {
  taTrack(TA_EVENT_MAP.enterPage, {
    page_web_id: nextPage,
    sub_page_web_id: subPage,
    page_web_entrance: entrance
  })
}

export function taLogout() {
  const loginTime = +(getSessionItem('login-time') ?? 0)
  if (loginTime > 0) {
    const duration = Math.round((Date.now() - loginTime) / 1000)
    taClearAccountId()
    taTrack(TA_EVENT_MAP.logout, { account_duration: duration })
    removeSessionItem('login-time')
  }
}
