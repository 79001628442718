import React from 'react'
import styles from './style.module.less'
import { ButtonProps } from '@/common/types'
import classNames from 'classnames'

type Props = ButtonProps

const TextButton: React.FC<Props> = ({ children, className, ...rest }) => {
  return (
    <button {...rest} className={classNames(className, styles.btn)}>
      {children}
    </button>
  )
}

export default React.memo(TextButton)
