import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { ORDER, WITHDRAW } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import Select from '@/components/Select'
import { StyledRangePicker } from '@/components/DatePicker'
import { Form } from 'antd'
import { ProfileButton } from '@/components/Button'
import { DepositRecord, WalletApi, WithdrawRecord } from '@/services/wallet'
import { errorNotice } from '@/common/tip'
import RechargeTable from '../components/RechargeTable'
import WithdrawTable from '../components/WithdrawTable'
import Pagination from '@/components/Pagination'
import { getSearchParams } from '@/utils/utils'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'
import { getDayEnd, getDayStart } from '@/common/profile'

const PAGE_SIZE = 10

const CurrencyRecord: React.FC = () => {
  const { type } = getSearchParams()
  const defaultType = type === 'withdraw' ? 1 : 0
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [tableType, setTableType] = useState(defaultType)
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [rechargeDS, setRechargeDS] = useState<DepositRecord[]>([])
  const [withdrawDS, setWithdrawDS] = useState<WithdrawRecord[]>([])

  function getParams() {
    const values = form.getFieldsValue()
    const { range } = values
    const startTime = getDayStart(range?.[0]?.valueOf())
    const endTime = getDayEnd(range?.[1]?.valueOf())
    return {
      pageNo,
      pageSize: PAGE_SIZE,
      startTime,
      endTime,
      ...values,
      range: undefined,
      tableType: undefined
    }
  }

  async function getDepositRecord() {
    const params = getParams()
    const res = await WalletApi.getDepositRecord({
      ...params,
      orderTypes: [1, 3],
      tokenTypes: [1, 2]
    })
    if (res.data.code === 0) {
      return { dataSource: res.data.data.data, total: +res.data.data.totals }
    } else {
      errorNotice(res.data.message)
      return { dataSource: [], total: 0 }
    }
  }

  async function getWithdrawRecord() {
    const res = await WalletApi.getWithdrawRecord({
      ...getParams(),
      assetType: 1
    })
    if (res.data.code === 0) {
      return { dataSource: res.data.data.data, total: +res.data.data.totals }
    } else {
      errorNotice(res.data.message)
      return { dataSource: [], total: 0 }
    }
  }

  async function submit() {
    const values = form.getFieldsValue()
    let t = 0
    if (values.tableType === 0) {
      const { dataSource, total } = await getDepositRecord()
      t = total
      setRechargeDS(dataSource)
    } else {
      const { dataSource, total } = await getWithdrawRecord()
      t = total
      setWithdrawDS(dataSource)
    }
    setTotal(t)
  }

  async function reset() {
    setPageNo(1)
    setTableType(0)
    form.resetFields(['range'])
    submit()
  }

  useEffect(() => {
    submit()
  }, [pageNo, tableType])

  return (
    <Page navigator={`${ORDER}/${WITHDRAW}`}>
      <TitleModule
        title={t('wallet.depositAndWithdrawalRecords')}
        more={false}
        className={styles.module}
      >
        <Form form={form} onFinish={submit} onReset={reset}>
          <div className={styles.search}>
            <Form.Item noStyle name="tableType" initialValue={defaultType}>
              <Select
                className={styles.formItem}
                options={[
                  { label: t('wallet.assets.deposit'), value: 0 },
                  { label: t('wallet.assets.withdraw'), value: 1 }
                ]}
                onChange={(v) => {
                  setTableType(v as number)
                  setPageNo(1)
                }}
              />
            </Form.Item>
            <div className={styles.formItem}>
              <Form.Item noStyle name="range">
                <StyledRangePicker
                  placeholder={[t('profile.startTime'), t('profile.endTime')]}
                  allowEmpty={[true, true]}
                />
              </Form.Item>
            </div>
            <div className={styles.btns}>
              <ProfileButton
                className={styles.btn}
                type="submit"
                onClick={() => setPageNo(1)}
              >
                {t('normal.search')}
              </ProfileButton>
              <ProfileButton className={styles.btn} type="reset">
                {t('normal.reset')}
              </ProfileButton>
            </div>
          </div>
        </Form>
        <div className={styles.tableCtn}>
          {tableType === 0 && (
            <Empty data={rechargeDS}>
              <RechargeTable dataSource={rechargeDS} />
              <div className={styles.paginationCtn}>
                <Pagination
                  total={total}
                  showSizeChanger={false}
                  onChange={(page) => setPageNo(page)}
                  current={pageNo}
                  pageSize={PAGE_SIZE}
                />
              </div>
            </Empty>
          )}
          {tableType === 1 && (
            <Empty data={withdrawDS}>
              <WithdrawTable dataSource={withdrawDS} />
              <div className={styles.paginationCtn}>
                <Pagination
                  total={total}
                  showSizeChanger={false}
                  onChange={(page) => setPageNo(page)}
                  current={pageNo}
                  pageSize={PAGE_SIZE}
                />
              </div>
            </Empty>
          )}
        </div>
      </TitleModule>
    </Page>
  )
}

export default React.memo(CurrencyRecord)
