import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type Props = {
  text: string
  direct?: string
  colors?: string[] | string
  className?: string
  inline?: boolean
}

const ColorfulText = ({
  text,
  inline = false,
  direct = 'right',
  colors = ['#8159E0', '#A953B6', '#D4706B'],
  className = ''
}: Props) => {
  const colorStr = Array.isArray(colors) ? colors.join(',') : colors
  const backgroundImage = `linear-gradient(to ${direct}, ${colorStr})`
  return (
    <div
      style={{ backgroundImage }}
      className={classNames(
        styles.colorfulText,
        inline && styles.inline,
        className
      )}
    >
      {text}
    </div>
  )
}

export default React.memo(ColorfulText)
