import React, { useState, useRef, useEffect } from 'react'
import styles from './style.module.less'
import Wrapper from '../Wrapper'
import RegisterForm from './RegisterForm'
import VerifyCodeForm from './VerifyCodeForm'
import { ModalProps } from '@/components/Modal'
import { WalletApi } from '@/services/wallet'
import { showTip } from '@/common/tip'
import { RESEND_TIME } from '@/constants/login'

export type RegisterParams = {
  email: string
  pwd: string
  registrationType: number
  username: string
  recommenderCode: string
}

const Register = ({ onDismiss }: ModalProps) => {
  const [isRegister, setRegister] = useState(true)

  const toRegister = () => setRegister(true)
  const toVerifyCode = () => setRegister(false)

  const registerParams = useRef<RegisterParams>()

  const [count, setCount] = useState(RESEND_TIME)

  const timer = useRef<NodeJS.Timer>()

  const sendEmail = async () => {
    clearInterval(timer.current)
    setCount(RESEND_TIME - 1)
    timer.current = setInterval(() => {
      setCount((c) => c - 1)
    }, 1000)

    const res = await WalletApi.getCodeFromEmail(registerParams.current!.email)
    if (res.data.code !== 0) {
      showTip(res.data.message)
    }
  }

  useEffect(() => {
    if (count === 0) {
      clearInterval(timer.current)
      setCount(RESEND_TIME)
    }
  }, [count])

  useEffect(() => {
    return () => clearInterval(timer.current)
  }, [])

  return (
    <Wrapper onDismiss={onDismiss}>
      <RegisterForm
        onDismiss={onDismiss}
        toVerifyCode={toVerifyCode}
        className={!isRegister ? styles.hidden : ''}
        onSaveParams={(params) => (registerParams.current = params)}
        sendEmail={sendEmail}
      />
      <VerifyCodeForm
        onDismiss={onDismiss}
        toRegister={toRegister}
        className={isRegister ? styles.hidden : ''}
        registerParams={registerParams.current!}
        sendEmail={sendEmail}
        count={count}
      />
    </Wrapper>
  )
}

export default Register
