import { AssetName } from '@/services/wallet'
import { opBNBTestnet, opBNB } from 'wagmi/chains'

const chainId = process.env.isTestOrDev ? opBNBTestnet.id : opBNB.id

const VOTE_DATA_MAP_DEV = {
  vote1: {
    activityId: 1004,
    periods: 1004,
    ticketKey: 'MEET_VOTE_TICKET_5' as AssetName,
    voteKey: 'MEET_VOTE_5',
    chainId,
    nftId: 1n
  }
}

const VOTE_DATA_MAP_PROD = {
  vote1: {
    activityId: 1004,
    periods: 1004,
    ticketKey: 'MEET_VOTE_TICKET_2' as AssetName,
    voteKey: 'MEET_VOTE_2',
    chainId,
    nftId: 1n
  }
}

export const VOTE_DATA_MAP = process.env.isTestOrDev
  ? VOTE_DATA_MAP_DEV
  : VOTE_DATA_MAP_PROD

const CURRENT_VOTE_KEY = 'vote1'

export const VOTE_DATA = VOTE_DATA_MAP[CURRENT_VOTE_KEY]

export const POINT_COIN_CODE = 'Point'
