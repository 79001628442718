import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { Space } from 'antd'
import { Option } from '@/common/types'

type Props = {
  options: Option[]
  value: string | number
  onChange: (val: string | number) => void
  className?: string
  size?: number
}

const ButtonGroup: React.FC<Props> = ({
  options,
  value,
  onChange,
  className = '',
  size = 22
}) => {
  return (
    <Space className={className} size={size}>
      {options.map((option) => {
        const isActive = value === option.value
        return (
          <div
            key={option.value}
            className={classNames(styles.btn, isActive && styles.active)}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </div>
        )
      })}
    </Space>
  )
}

export default React.memo(ButtonGroup)
