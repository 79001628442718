const ERROR_STATUS_MAP = new Map([
  [400, { title: 'Bad Request', detail: 'http.400' }],
  [401, { title: 'Unauthorized', detail: 'http.401' }],
  [403, { title: 'Forbidden', detail: 'http.403' }],
  [404, { title: 'Not Found', detail: 'http.404' }],
  [405, { title: 'Method Not Allowed', detail: 'http.405' }],
  [
    406,
    {
      title: 'Not Acceptable',
      detail: 'http.406'
    }
  ],
  [410, { title: 'Gone', detail: 'http.410' }],
  [
    422,
    {
      title: 'Unprocessable Entity',
      detail: 'http.422'
    }
  ],
  [500, { title: 'Internal Server Error', detail: 'http.500' }],
  [
    502,
    {
      title: 'Bad Gateway',
      detail: 'http.502'
    }
  ],
  [
    503,
    {
      title: 'Service Unavailable',
      detail: 'http.503'
    }
  ],
  [
    504,
    {
      title: 'Gateway Timeout',
      detail: 'http.504'
    }
  ]
])

export default ERROR_STATUS_MAP
