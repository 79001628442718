import React from 'react'
import styles from './style.module.less'
import ColorfulText from '@/components/ColorfulText'
import { EllipsisIcon, FileIcon, SwitchIcon } from '@/assets'
import { useHistory } from 'react-router-dom'
import { NFT } from '@/services/userAPI'
import { getLangData, getMediaSrc } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import { DefaultNFT } from '../../image'

type Props = {
  data: NFT
}

const Item: React.FC<Props> = ({ data }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const name = getLangData(data.goodsNameI18n)
  const src = getMediaSrc(data.showImageUrl) || DefaultNFT

  function toDetail() {
    history.push(`/profile/nft-detail?id=${data.id}&tokenId=${data.tokenId}`)
  }

  function toWithdraw() {
    const { id, tokenId } = data
    history.push(`/profile/nft-withdraw?nftId=${id}&tokenId=${tokenId}`)
  }

  return (
    <div className={styles.item} onClick={toDetail}>
      <div className={styles.imgCtn}>
        <img src={src} className={styles.img} />
      </div>
      <div className={styles.detail}>
        <div className={styles.name}>{name}</div>
        <div className={styles.bottom}>
          <ColorfulText
            className={styles.colorText}
            text={`${data.exchangePrice} ${t('gifr.pick')}`}
            colors={['#9A6BFE', '#FC78FF']}
            inline
          />
          <div className={styles.iconCtn} onClick={(e) => e.stopPropagation()}>
            <img src={EllipsisIcon} className={styles.icon} />
            <div className={styles.popup}>
              <div className={styles.line} onClick={toDetail}>
                <img src={FileIcon} className={styles.popupIcon} />
                <span>{t('wallet.nft.detail')}</span>
              </div>
              {data.withdrawEnable === 1 && (
                <div className={styles.line} onClick={toWithdraw}>
                  <img src={SwitchIcon} className={styles.popupIcon} />
                  <span>{t('wallet.nft.transfer')}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Item)
