import ChainDescription from '@/components/ChainDescription'
import { notification, message } from 'antd'
import i18next from 'i18next'
import React from 'react'
import { Root, createRoot } from 'react-dom/client'
import { IconType } from 'antd/lib/notification'

const notify = (
  msg: string,
  desc = '',
  type: IconType = 'error',
  destroy = false
) => {
  if (destroy) {
    notification.destroy()
  }
  notification[type]({ message: msg, description: desc })
}

const notice = (msg: string, type: IconType = 'warning', destroy = false) => {
  // if (destroy) {
  //   message.destroy()
  // }
  // message[type](msg)
  notify('', msg, type, destroy)
}

const errorNotice = (error: unknown, destroy = false) => {
  try {
    if (error instanceof Error) {
      throw error
    }
    throw new Error(
      typeof error === 'object' ? JSON.stringify(error) : String(error)
    )
  } catch (e: unknown) {
    if ((e as Error).message === '') {
      return
    }
    notify('Error', (e as Error).message, 'error', destroy)
  }
}

function getError(error: unknown) {
  if (error instanceof Error) {
    return error
  }
  return new Error(
    typeof error === 'object' ? JSON.stringify(error) : String(error)
  )
}

export const web3ErrorNotice = (error: any) => {
  const e = getError(error)
  // 分割空行的正则
  const reg = /\n\s*\n/
  errorNotice(e.message.split(reg)[0], true)
}

const verifyChain = (chain: any) => {
  return true
  if (chain?.id === 97) {
    return true
  }
  notification.destroy()
  notification.warning({
    message: i18next.t('login.chainTip') as string,
    description: <ChainDescription />,
    placement: 'top',
    style: { width: 480 }
  })
  return false
}

// delay期间如果有调用，会在delay后继续调一次函数的节流函数，多次调用取最后一个
function throttle(func: (...rest: any[]) => void, delay = 300) {
  let lastTime = 0
  let timer: NodeJS.Timer

  return function (...rest: any[]) {
    const now = Date.now()
    clearTimeout(timer)

    if (now - lastTime >= delay) {
      func(...rest)
      lastTime = now
    } else {
      timer = setTimeout(
        () => {
          func(...rest)
          lastTime = now
        },
        lastTime + delay - now
      )
    }
  }
}

let destroyTimer: NodeJS.Timer
let root: Root

const showTip = throttle((text: string) => {
  clearTimeout(destroyTimer)
  let target = document.querySelector('.global-tip')
  if (!target) {
    target = document.createElement('div')
    target.classList.add('global-tip')
    document.body.appendChild(target)
    root = createRoot(target)
  }
  root.render(<div className="globalTip">{text}</div>)

  destroyTimer = setTimeout(destroyTip, 5000)
}, 3000)

const destroyTip = () => {
  if (root) {
    root.render(null)
  }
}

export { notice, notify, errorNotice, verifyChain, showTip, destroyTip }
