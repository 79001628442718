import React, { useState } from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type Props = {
  children: React.ReactNode
  src: string
}

const QrCodePopOver = ({ children, src }: Props) => {
  const [open, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  return (
    <div className={styles.popover} onMouseOver={onOpen} onMouseOut={onClose}>
      {children}
      <div className={classNames(styles.popoverBg, !open && styles.hidden)}>
        <img className={styles.qrCode} src={src} />
      </div>
    </div>
  )
}

export default QrCodePopOver
