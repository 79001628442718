import React from 'react'
import styles from './style.module.less'
import ModalWrapper from '@/components/ModalWrapper'
import { Warning } from '@/assets'
import { Button } from '@/components/Button'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

type Props = {
  onDismiss?: () => void
  onOk?: (data: unknown) => void
}

const UnbindModal: React.FC<Props> = ({ onDismiss, onOk }) => {
  const { t } = useTranslation()
  return (
    <ModalWrapper title="" hasClose={false} width={407}>
      <div className={styles.content}>
        <img src={Warning} className={styles.icon} />
        <div className={styles.title}>
          {t('wallet.verify.unbindGoogleTitle')}
        </div>
        <div>{t('wallet.verify.unbindGoogleDetail')}</div>
        <div className={styles.btns}>
          <Button className={styles.btn} onClick={onOk}>
            {t('normal.ok')}
          </Button>
          <Button
            className={classNames(styles.btn, styles.cancel)}
            onClick={onDismiss}
          >
            {t('normal.cancel')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default React.memo(UnbindModal)
