import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { NFT_TRANSFER_RECORDS, ORDER } from '@/constants/profile'
import { Page, TitleModule } from '../components'
import { StyledRangePicker } from '@/components/DatePicker'
import { Form } from 'antd'
import { ProfileButton } from '@/components/Button'
import { DepositRecord, WalletApi } from '@/services/wallet'
import { errorNotice } from '@/common/tip'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'
import Table from './Table'
import { getDayEnd, getDayStart } from '@/common/profile'

const PAGE_SIZE = 10

const NFTTransferRecords: React.FC = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [pageNo, setPageNo] = useState(1)
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<DepositRecord[]>([])

  function getParams() {
    const values = form.getFieldsValue()
    const { range } = values
    const startTime = getDayStart(range?.[0]?.valueOf())
    const endTime = getDayEnd(range?.[1]?.valueOf())
    return {
      pageNo,
      pageSize: PAGE_SIZE,
      startTime,
      endTime,
      ...values,
      range: undefined
    }
  }

  async function getNFTTransferRecord() {
    const params = getParams()
    const res = await WalletApi.getDepositRecord({
      ...params,
      // orderTypes: [1, 3],
      tokenTypes: [3, 4]
    })
    if (res.data.code === 0) {
      return { dataSource: res.data.data.data, total: +res.data.data.totals }
    } else {
      errorNotice(res.data.message)
      return { dataSource: [], total: 0 }
    }
  }

  async function submit() {
    const { dataSource, total } = await getNFTTransferRecord()
    setTotal(total)
    setDataSource(dataSource)
  }

  async function reset() {
    setPageNo(1)
    form.resetFields(['range'])
    submit()
  }

  useEffect(() => {
    submit()
  }, [pageNo])

  return (
    <Page navigator={`${ORDER}/${NFT_TRANSFER_RECORDS}`}>
      <TitleModule
        title={t('profile.nftTransferRecord')}
        more={false}
        className={styles.module}
      >
        <Form form={form} onFinish={submit} onReset={reset}>
          <div className={styles.search}>
            <div className={styles.formItem}>
              <Form.Item noStyle name="range">
                <StyledRangePicker
                  placeholder={[t('profile.startTime'), t('profile.endTime')]}
                  allowEmpty={[true, true]}
                />
              </Form.Item>
            </div>
            <div className={styles.btns}>
              <ProfileButton
                className={styles.btn}
                type="submit"
                onClick={() => setPageNo(1)}
              >
                {t('normal.search')}
              </ProfileButton>
              <ProfileButton className={styles.btn} type="reset">
                {t('normal.reset')}
              </ProfileButton>
            </div>
          </div>
        </Form>
        <div className={styles.tableCtn}>
          <Empty data={dataSource}>
            <Table dataSource={dataSource} />
            <div className={styles.paginationCtn}>
              <Pagination
                total={total}
                showSizeChanger={false}
                onChange={(page) => setPageNo(page)}
                current={pageNo}
                pageSize={PAGE_SIZE}
              />
            </div>
          </Empty>
        </div>
      </TitleModule>
    </Page>
  )
}

export default React.memo(NFTTransferRecords)
