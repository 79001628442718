import React, { PropsWithChildren } from 'react'
import { ModalProps } from '@/components/Modal'
import styles from './style.module.less'
import { CloseIcon } from '@/assets'

interface ModalWrapperProp extends ModalProps {
  width?: number | string
  title?: React.ReactNode
  hasClose?: boolean
}

const ModalWrapper: React.FC<PropsWithChildren<ModalWrapperProp>> = ({
  width = 650,
  title = '',
  hasClose = true,
  children,
  onDismiss
}) => {
  return (
    <div className={styles.taskModal} style={{ width }}>
      <div className={styles.taskModalContent}>
        <div className={styles.title}>{title}</div>
        {hasClose && (
          <img
            src={CloseIcon}
            alt="close"
            className={styles.close}
            onClick={onDismiss}
          />
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default ModalWrapper
