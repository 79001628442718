import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type Props = {
  className?: string
}

const Module: React.FC<PropsWithChildren<Props>> = ({
  children,
  className = ''
}) => {
  return <div className={classNames(styles.module, className)}>{children}</div>
}

export default React.memo(Module)
