import React, { useMemo } from 'react'
import styles from './style.module.less'
import { formatTime, getMediaSrc } from '@/utils/utils'
import { useHistory } from 'react-router-dom'
import { Draft } from '@/common/dazzle'
import ImgPreview from '@/components/ImgPreview'
import Radio from '../../components/Radio'
import { notice } from '@/common/tip'
import { useTranslation } from 'react-i18next'

type Props = {
  info: Draft
  editing?: boolean
  selected?: string[]
  onSelect?: (id: string) => void
}

const DraftPreview: React.FC<Props> = ({
  info,
  editing = false,
  selected = [],
  onSelect
}) => {
  const { title, coverImageUrl, id } = info
  const { t } = useTranslation()

  const history = useHistory()
  const src = getMediaSrc(coverImageUrl)

  const isSelected = selected.includes(id)

  const videoInfo = useMemo(() => {
    if (editing) {
      return <Radio checked={isSelected} />
    }
    return <div>{formatTime(+id, { format: 'YYYY.MM.DD' })}</div>
  }, [info, editing, isSelected])

  function clickHandle() {
    if (editing) {
      onSelect?.(id)
      return
    }
    // notice(t('dazzle.endTip'))
    history.push(`/dazzle-upload?id=${id}`)
  }

  return (
    <div className={styles.draftPreview} onClick={clickHandle}>
      <ImgPreview src={src} className={styles.imgCtn}>
        <div className={styles.videoInfo}>
          <div className={styles.left}>{videoInfo}</div>
        </div>
      </ImgPreview>
      <div className={styles.title}>{title}</div>
    </div>
  )
}

export default React.memo(DraftPreview)
