import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import Dropdown from '@/components/Dropdown'
import { DownArrow } from '@/assets'
import classNames from 'classnames'
import { Token } from '@/services/wallet'
import { useTranslation } from 'react-i18next'

type Props = {
  label?: string
  className?: string
  placeholder?: string
  value?: number
  networks: Token[]
  onChange?: (chainCode: number) => void
  width?: number
  disabled?: boolean
}

type FormProps = {
  networks: Token[]
  onChange?: (val: number) => void
  close: () => void
  width?: number
}

function Form({ onChange, close, networks, width }: FormProps) {
  const { t } = useTranslation()

  function handleChange(chainCode: number) {
    onChange?.(chainCode)
    close()
  }

  return (
    <div className={styles.form}>
      <div className={styles.content}>
        <div className={styles.tip} style={{ width }}>
          {t('wallet.address.selectNetModalTip')}
        </div>
        <div className={styles.list}>
          {networks.map((network) => {
            return (
              <div
                key={network.id}
                className={styles.line}
                onClick={() => handleChange?.(network.chainId)}
              >
                <div>
                  <div className={styles.name}>{network.chainFullName}</div>
                  <div className={styles.chainCode}>{network.chainCode}</div>
                </div>
                <div className={styles.right}>
                  <div className={styles.name}>
                    {((network.blockTime * network.safeBlockNo) / 60).toFixed(
                      1
                    )}{' '}
                    {t('normal.minute')}
                  </div>
                  <div>
                    {network.safeBlockNo} {t('wallet.address.confirmations')}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const NetworkSelect: React.FC<Props> = ({
  label,
  className,
  placeholder = 'wallet.address.selectCoin',
  value,
  onChange,
  networks,
  width = 990,
  disabled = false
}) => {
  const [open, setOpen] = useState(false)

  function close() {
    setOpen(false)
  }

  const text = useMemo(() => {
    const network = networks.find((n) => n.chainId === value)
    const Item = (
      <div className={styles.item}>
        <span className={styles.name}>{network?.chainFullName}</span>
        <span className={styles.chainCode}>{network?.chainCode}</span>
      </div>
    )
    return network ? Item : value || placeholder
  }, [networks, value])

  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <Dropdown
        onOpenChange={(open) => {
          if (disabled) {
            return
          }
          setOpen(open)
        }}
        trigger={['click']}
        open={open}
        dropdownRender={() => {
          return (
            <Form
              width={width}
              onChange={onChange}
              close={close}
              networks={networks}
            />
          )
        }}
      >
        <div
          className={styles.select}
          onClick={() => {
            if (disabled) {
              return
            }
            setOpen((o) => !o)
          }}
        >
          <div className={value ? styles.selected : ''}>{text}</div>
          <img
            src={DownArrow}
            className={classNames(styles.arrow, open && styles.openedArrow)}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default React.memo(NetworkSelect)
