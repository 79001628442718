import { HomemadeVideo, SubmitVideoParams } from '@/services/MediaAPI'
import { getJsonData } from './dataConvert'
import { getLocalItem, setLocalItem } from './storage'
import { DRAFT } from '@/constants/keys'
import { notice } from './tip'
import { FOLDER_SIZE } from '@/constants/dazzle'
import i18next from 'i18next'
import { useUserInfo } from '@/store/useUserInfo'

export type Draft = SubmitVideoParams & {
  id: string
  accountId: string
}

// 获取当前用户的草稿
export function getUserDrafts() {
  const userInfo = useUserInfo.getState().info
  if (!userInfo) {
    return []
  }
  const list: Draft[] = getDrafts()
  return list.filter((draft) => draft.accountId === userInfo.accountId)
}

export function addDraft(draft: Draft) {
  const list = getUserDrafts()
  if (list.length === FOLDER_SIZE) {
    notice(i18next.t('dazzle.draftBoxFull'))
    return false
  }
  setLocalItem(DRAFT, JSON.stringify([...getDrafts(), draft]))
  return true
}

export function updateDraft(draft: Draft) {
  const list: Draft[] = getDrafts()
  const index = list.findIndex((item) => item.id === draft.id)
  const newList = [...list.slice(0, index), draft, ...list.slice(index + 1)]
  setLocalItem(DRAFT, JSON.stringify(newList))
  return true
}

export function removeDraft(ids: string[]) {
  const list: Draft[] = getDrafts()
  const newList = list.filter((item) => !ids.includes(item.id))
  setLocalItem(DRAFT, JSON.stringify(newList))
}

export function clearUserDraft() {
  const ids = getUserDrafts().map((draft) => draft.id)
  removeDraft(ids)
}

// 获取所有草稿
export function getDrafts(): Draft[] {
  return getJsonData(getLocalItem(DRAFT)) || []
}

export function getDraft(id: string): Draft | undefined {
  const list: Draft[] = getUserDrafts()
  return list.find((item) => item.id === id)
}

export function getStatus(data?: HomemadeVideo | null) {
  if (!data) {
    return 0
  }
  // 判断的字段有优先级
  if (data.auditStatus === 2) {
    return 2 // 审核中
  }
  if (data.status === 1) {
    return 1 // 已发布
  }
  if (data.takeDownType === 1) {
    return 4 // 已下架 - 运营下架
  }
  if (data.takeDownType === 2) {
    return 3 // 已下架 - 用户下架
  }
  if (data.auditStatus === 0) {
    return 5 // 未通过
  }
  // 0 为未知情况，应该不会出现
  return 0
}

const statusMap: Record<number, string> = {
  1: 'dazzle.public',
  2: 'dazzle.underReview',
  3: 'dazzle.hidden',
  4: 'dazzle.delisted',
  5: 'dazzle.rejected'
}

export function getStatusText(data?: HomemadeVideo | null) {
  if (!data) {
    return ''
  }
  const status = getStatus(data)
  return statusMap[status] || ''
}
