import React, { useState } from 'react'
import styles from './style.module.less'
import GoBackTitle from '../components/GoBackTitle'
import { PERSONAL } from '@/constants/profile'
import { Form, Space } from 'antd'
import Select from '@/components/Select'
import { IconButton, ProfileButton } from '@/components/Button'
import { Address } from '../components'
import Module from '@/components/Module'
import { Page } from '../components'
import { useHistory } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { SafeVerificationParams, WalletApi } from '@/services/wallet'
import Pagination from '@/components/Pagination'
import Empty from '@/components/Empty'
import { DeleteWhiteIcon } from '@/assets'
import { errorNotice, notice } from '@/common/tip'
import { useSafeVerification } from '@/store/useSafeVerification'
import { useTranslation } from 'react-i18next'

const PAGE_SIZE = 10

const AddressManage: React.FC = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const [pageNo, setPageNo] = useState(1)
  const [selected, setSelected] = useState<string[]>([])
  const { setCallback } = useSafeVerification()

  const { data: supportList = [] } = useQuery(WalletApi.getCoinSupportList)

  function handleSelect(id: string) {
    if (selected.includes(id)) {
      setSelected((list) => list.filter((item) => item !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const { data, refresh } = useQuery(() => {
    const values = form.getFieldsValue()
    const params = {
      coinId: values.coinId === 0 ? undefined : values.coinId,
      addressType: values.addressType === 0 ? undefined : values.addressType
    }
    return WalletApi.getUserWithdrawAddressList({
      pageSize: PAGE_SIZE,
      pageNo,
      ...params
    })
  }, [pageNo])

  const { records = [], total = 0 } = data ?? {}

  async function handleDelete(params: SafeVerificationParams) {
    const res = await WalletApi.deleteUserWithdrawAddress({
      id: selected,
      ...params
    })
    if (res.data.code === 0) {
      notice(t('wallet.deleteSuccess'), 'success')
      refresh()
      history.push('/profile/address-manage')
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <Page navigator={PERSONAL}>
      <Module className={styles.withdrawManage}>
        <GoBackTitle
          title={t('wallet.address.addressManage')}
          onClick={() => {
            history.push('/personal')
          }}
        />
        <Form form={form} onFieldsChange={refresh}>
          <div className={styles.search}>
            <div className={styles.form}>
              <Space size={20}>
                <label>{t('wallet.currency')}</label>
                <Form.Item noStyle name="coinId" initialValue={0}>
                  <Select
                    options={[
                      { label: t('normal.all'), value: 0 },
                      ...supportList.map((item) => ({
                        value: item.id,
                        label: item.coinName
                      }))
                    ]}
                    className={styles.formItem}
                  />
                </Form.Item>
              </Space>
              <Space size={20}>
                <label>{t('wallet.address.type')}</label>
                <Form.Item noStyle name="addressType" initialValue={0}>
                  <Select
                    options={[
                      { label: t('normal.all'), value: 0 },
                      { label: t('wallet.address.universal'), value: 1 },
                      { label: t('wallet.address.ordinary'), value: 2 }
                    ]}
                    className={styles.formItem}
                  />
                </Form.Item>
              </Space>
            </div>
            <Space size={40}>
              <IconButton
                src={DeleteWhiteIcon}
                onClick={() => {
                  setCallback(handleDelete)
                  history.push(`/profile/safety-verification?type=9`)
                }}
                disabled={selected.length === 0}
              />
              <ProfileButton
                className={styles.btn}
                onClick={() => history.push('/profile/add-address')}
              >
                {t('wallet.address.addAddress')}
              </ProfileButton>
            </Space>
          </div>
        </Form>
        <div className={styles.addressList}>
          <Empty data={records}>
            {records.map((record) => {
              return (
                <Address
                  key={record.id}
                  data={record}
                  onSelect={handleSelect}
                  selected={selected.includes(record.id)}
                />
              )
            })}
          </Empty>
        </div>
        <div className={styles.paginationCtn}>
          <Pagination
            total={+total}
            current={pageNo}
            showSizeChanger={false}
            onChange={(page) => setPageNo(page)}
            pageSize={PAGE_SIZE}
          />
        </div>
      </Module>
    </Page>
  )
}

export default React.memo(AddressManage)
