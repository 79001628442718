import React from 'react'
import styles from './style.module.less'
import { Space } from 'antd'
import { ProfileButton } from '@/components/Button'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useUserInfo } from '@/store/useUserInfo'
import { useMediaInfo } from '@/store/useMediaInfo'
import { notice } from '@/common/tip'
import UnbindModal from '../UnbindModal'
import { useModal } from '@/store/useModal'

type Props = {
  icon: string
  title: string
  bound?: boolean
  onClick: (title: string) => void
}

const Media: React.FC<Props> = ({ icon, title, bound = false, onClick }) => {
  const { t } = useTranslation()
  const { info } = useUserInfo()

  const { mediaInfo, loading } = useMediaInfo()
  const username =
    title === 'Twitter' ? mediaInfo?.twitterName : mediaInfo?.discordName

  const { open } = useModal()

  function handleClick() {
    if (!info) {
      notice(t('task.logFirst'))
      return
    }
    const type = title as 'Discord' | 'Twitter'
    if (bound) {
      open(<UnbindModal username={username} type={type} />)
    } else {
      onClick(title)
    }
  }

  return (
    <div className={styles.media} onClick={(e) => e.preventDefault()}>
      <Space size={10}>
        <img
          src={icon}
          className={classNames(styles.icon, !bound && styles.unbindIcon)}
        />
        <span className={classNames(bound && styles.boundTitle)}>
          {username || title}
        </span>
      </Space>
      <ProfileButton
        className={classNames(bound && styles.boundBtn)}
        onClick={handleClick}
        disabled={loading}
      >
        {bound ? t('profile.unbind') : t('profile.bind')}
      </ProfileButton>
    </div>
  )
}

export default React.memo(Media)
