import React from 'react'
import styles from './style.module.less'
import { DownArrow } from '@/assets'
import { useHistory } from 'react-router-dom'

type Props = {
  title: string
  onClick?: () => void
}

const GoBackTitle: React.FC<Props> = ({ title, onClick }) => {
  const history = useHistory()
  function handleClick() {
    if (onClick) {
      onClick()
      return
    }
    history.goBack()
  }
  return (
    <div className={styles.goBackTitle} onClick={handleClick}>
      <img src={DownArrow} className={styles.arrow} />
      <div>{title}</div>
    </div>
  )
}

export default React.memo(GoBackTitle)
