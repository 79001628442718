import React, { useState, useMemo } from 'react'
import styles from './style.module.less'
import { useTranslation } from 'react-i18next'
import InputBox from '../../InputBox'
import { ModalProps } from '@/components/Modal'
import { Arrow } from '@/assets'
import { Button } from '@/components/Button'
import { destroyTip, notice, showTip } from '@/common/tip'
import { WalletApi } from '@/services/wallet'
import { rsaEncrypt } from '@/utils/utils'
import { checkPassword, getEmailValid } from '@/common/login'

type Props = ModalProps & {
  toLogin: () => void
  className?: string
}

const ForgetForm = ({ onDismiss, toLogin, className = '' }: Props) => {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [pwd, setPwd] = useState('')

  function initForm() {
    setEmail('')
    setCode('')
    setPwd('')
  }

  const disabled = useMemo(() => {
    return email.length === 0 || code.length === 0 || pwd.length === 0
  }, [email, code, pwd])

  const submit = async () => {
    if (disabled) {
      return
    }
    if (!getEmailValid(email)) {
      return
    }
    if (!checkPassword(pwd)) {
      return
    }
    const res = await WalletApi.forgetPassword({
      code: code,
      email: rsaEncrypt(email) as string,
      newPassword: rsaEncrypt(pwd) as string
    })
    if (res.data.code === 0) {
      notice(t('login.resetPasswordSuccess'), 'success')
      destroyTip()
      toLogin()
      initForm()
    } else {
      showTip(res.data.message)
    }
  }

  return (
    <div className={className}>
      <div className={styles.title}>{t('login.forget')}</div>
      <InputBox
        title={t('login.inputEmail')}
        value={email}
        onChange={(v) => setEmail(v.trim())}
      />
      <InputBox
        title={t('login.enterEmailVerificationCode')}
        value={code}
        onChange={(v) => setCode(v.trim())}
        hasSend
        email={email}
      />
      <InputBox
        title={t('login.resetPasswordTip')}
        value={pwd}
        onChange={(v) => setPwd(v)}
        isPassword
      />
      <div className={styles.btns}>
        <div className={styles.arrowCtn} onClick={toLogin}>
          <img src={Arrow} className={styles.arrow} />
        </div>
        <Button disabled={disabled} className={styles.submit} onClick={submit}>
          {t('login.resetPassword')}
        </Button>
      </div>
    </div>
  )
}

export default ForgetForm
