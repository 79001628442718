import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './style.module.less'
import { PERSONAL } from '@/constants/profile'
import GoBackTitle from '../components/GoBackTitle'
import InputBox from '@/components/InputBox'
import { Form, Space } from 'antd'
import { Button } from '@/components/Button'
import classNames from 'classnames'
import AddressSelect from '../components/AddressSelect'
import Module from '@/components/Module'
import { CurrencySelect, Page } from '../components'
import { useHistory } from 'react-router-dom'
import Checkbox from '@/components/Checkbox'
import { SupportCoin, WalletApi } from '@/services/wallet'
import { errorNotice, notice } from '@/common/tip'
import { useUserInfo } from '@/store/useUserInfo'
import { useTranslation } from 'react-i18next'
import { useEvents } from '@/store/useEvents'
import { getSearchParams } from '@/utils/utils'
import NetworkSelect from '../components/NetworkSelect'
import useQuery from '@/hooks/useQuery'

const { LabelInput } = InputBox

const AddAddress: React.FC = () => {
  const { execRouteChangeEvent, clearRouteChangeEvent } = useEvents()
  const data = useRef(execRouteChangeEvent())
  const { id } = getSearchParams()
  const history = useHistory()
  const [form] = Form.useForm()
  const [currency, setCurrency] = useState<SupportCoin>()
  const { info } = useUserInfo()
  const { t } = useTranslation()
  const { data: supportList = [] } = useQuery(WalletApi.getCoinSupportList)

  useEffect(() => {
    if (supportList.length > 0 && data.current) {
      const c = supportList.find((item) => item.id === data.current.coinId)
      setCurrency(c)
      form.setFieldValue('coinId', c)
    }
  }, [supportList])

  function getInitialValues() {
    if (!data.current) {
      return
    }
    return {
      remark: data.current.remark,
      address: data.current.address,
      addressType: data.current.addressType === 1,
      chainId: data.current.chainId,
      source: `${data.current.sourceType}_${data.current.sourceCode}`
    }
  }

  useEffect(() => {
    return () => {
      clearRouteChangeEvent()
    }
  }, [])

  async function onSubmit(values: any) {
    if (!info) {
      notice(t('task.logFirst'))
      return
    }
    if (!values.remark) {
      notice(t('wallet.form.addressNotesTip'))
      return
    }
    if (values.remark.length < 4 || values.remark.length > 20) {
      notice(t('wallet.form.addressNotesFormatTip'))
      return
    }
    if (!currency) {
      notice(t('wallet.form.selectCoinTip'))
      return
    }
    if (!values.chainId) {
      notice(t('wallet.form.selectNetworkTip'))
      return
    }
    if (!values.address) {
      notice(t('wallet.form.selectWithdrawalAddressTip'))
      return
    }
    const source = (values.source ?? '').split('_')
    const sourceType = source[0] || undefined
    const sourceCode = source[1]
    const tokenId = currency?.tokenList.find(
      (token) => token.chainId === values.chainId
    )!.id
    const res = await WalletApi.addOrUpdateUserWithdrawAddress({
      ...values,
      coinId: currency.id,
      addressType: values.addressType ? 1 : 2,
      sourceType,
      sourceCode,
      id,
      tokenId,
      source: undefined
    })
    if (res.data.code === 0) {
      notice(t('normal.saveSuccess'), 'success')
      history.goBack()
    } else {
      errorNotice(res.data.message)
    }
  }

  const networks = currency?.tokenList ?? []

  return (
    <Page navigator={PERSONAL}>
      <Module className={styles.addAddress}>
        <GoBackTitle
          title={
            id
              ? t('wallet.address.editAddress')
              : t('wallet.address.addAddress')
          }
          onClick={history.goBack}
        />
        <Form
          form={form}
          className={styles.form}
          onFinish={onSubmit}
          initialValues={getInitialValues()}
        >
          <div className={styles.formItem}>
            <Form.Item noStyle name="remark">
              <LabelInput
                label={t('wallet.address.addressNotes')}
                placeholder={t('wallet.address.addressNotesPlaceholder')}
              />
            </Form.Item>
          </div>
          <div className={styles.formItem}>
            <Form.Item noStyle name="coinId">
              <CurrencySelect
                label={t('wallet.currency')}
                placeholder={t('wallet.address.selectCoin')}
                onChange={(data) => {
                  setCurrency(data)
                  form.setFieldValue('chainId', undefined)
                }}
              />
            </Form.Item>
            <Space size={12} className={styles.space}>
              <Form.Item noStyle name="addressType" valuePropName={'checked'}>
                <Checkbox />
              </Form.Item>
              <span>{t('wallet.address.addressTypeTip')}</span>
            </Space>
          </div>
          <div className={styles.formItem}>
            <Form.Item noStyle name="chainId" dependencies={['currency']}>
              <NetworkSelect
                label={t('wallet.address.selectNetWork')}
                placeholder={t('wallet.address.selectNetWork')}
                networks={networks}
              />
            </Form.Item>
          </div>
          <div className={styles.formItem}>
            <Form.Item noStyle name="address">
              <LabelInput
                label={t('wallet.address.withdrawalAddress')}
                placeholder={t('wallet.address.withdrawalAddressPlaceholder')}
              />
            </Form.Item>
          </div>
          <div className={styles.formItem}>
            <Form.Item noStyle name="source">
              <AddressSelect
                label={`${t('wallet.address.addressSource')}`}
                placeholder={t('normal.select')}
              />
            </Form.Item>
          </div>
          <Space size={30}>
            <Button className={styles.btn} type="submit">
              {t('normal.save')}
            </Button>
            <Button
              className={classNames(styles.btn, styles.cancel)}
              onClick={(e) => {
                e.preventDefault()
                history.goBack()
              }}
            >
              {t('normal.cancel')}
            </Button>
          </Space>
        </Form>
      </Module>
    </Page>
  )
}

export default React.memo(AddAddress)
