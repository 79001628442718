import React from 'react'
import styles from './style.module.less'
import type { DatePickerProps } from 'antd'
import StyledDatePicker from '../StyledDatePicker'

type Props = {
  label?: string
} & DatePickerProps

const DatePicker: React.FC<Props> = ({ label, ...props }) => {
  return (
    <div className={styles.component}>
      {label && <div className={styles.label}>{label}</div>}
      <StyledDatePicker {...props} />
    </div>
  )
}

export default React.memo(DatePicker)
