import { errorNotice } from '@/common/tip'
import { ExchangeRecordType, SearchItem, ShopAPI } from '@/services/shopAPI'
import { create } from 'zustand'

type State = { allOptions: ExchangeRecordType[] }

type Actions = {
  getOptions: () => void
  getOptionByType: (type: 1 | 2 | 3) => SearchItem[]
}

const useOrderOptions = create<State & Actions>((set, get) => ({
  allOptions: [],
  async getOptions() {
    const res = await ShopAPI.getExchangeRecordsType()
    if (res.data.code === 0) {
      set({ allOptions: res.data.data })
    } else {
      errorNotice(res.data.message)
    }
  },
  getOptionByType(type: 1 | 2 | 3) {
    return (
      get().allOptions.find((item) => item.orderClassType === type)
        ?.searchList ?? []
    )
  }
}))

export { useOrderOptions }
