import React, { useEffect, useRef, useState } from 'react'
import styles from './style.module.less'
import {
  FullScreenIcon,
  PauseIcon,
  ExitFullScreenIcon,
  PlayIcon
} from '@/assets'
import classNames from 'classnames'
import { getVideoDuration } from '@/utils/utils'
import { errorNotice } from '@/common/tip'

type Props = {
  videoRef: React.RefObject<HTMLVideoElement>
  className?: string
  id?: string
}

const initialPause = true

const VideoControls: React.FC<Props> = ({ className, videoRef, id }) => {
  const [isPause, setIsPause] = useState(initialPause)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const canplay = useRef(false)

  const isPlaying = useRef(false)

  const isWaiting = useRef(false)

  function isFullscreen() {
    return document.fullscreenElement === document.querySelector('#video-ctn')
  }

  function handleFullscreenChange() {
    setIsFullScreen(isFullscreen())
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', handleFullscreenChange)
    document.addEventListener('MSFullscreenChange', handleFullscreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      )
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange
      )
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange)
    }
  }, [])

  function handleFullScreen() {
    if (isFullscreen()) {
      document.exitFullscreen()
    } else {
      document.querySelector('#video-ctn')?.requestFullscreen()
    }
  }

  async function handlePlay() {
    if (!canplay.current) {
      return
    }
    if (isWaiting.current) {
      return
    }
    try {
      if (videoRef.current?.paused && !isPlaying.current) {
        isWaiting.current = true
        await videoRef.current?.play()
        isWaiting.current = false
        // setIsPause(Boolean(videoRef.current?.paused))
        return
      }
      if (!videoRef.current?.paused && isPlaying.current) {
        videoRef.current?.pause()
        // setIsPause(Boolean(videoRef.current?.paused))
      }
    } catch (e) {
      console.log(e)
      // errorNotice(e)
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onloadedmetadata = function () {
        setDuration(videoRef.current?.duration || 0)
      }
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.ontimeupdate = function (e) {
        setCurrentTime(videoRef.current?.currentTime || 0)
      }
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onplaying = function (e) {
        isPlaying.current = true
        setIsPause(false)
      }
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onpause = function (e) {
        isPlaying.current = false
        setIsPause(true)
      }
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.oncanplay = function (e) {
        console.log('canplay')
        canplay.current = true
      }
    }
  }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.onerror = function (e: any) {
        setIsPause(true)
        const error = e.target.error
        let message = ''

        switch (error.code) {
          case error.MEDIA_ERR_ABORTED:
            message = 'Video playback was aborted.'
            break
          case error.MEDIA_ERR_NETWORK:
            message = 'A network error caused the video download to fail.'
            break
          case error.MEDIA_ERR_DECODE:
            message =
              'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.'
            break
          case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
            message =
              'The video could not be loaded, either because the server or network failed or because the format is not supported.'
            break
          default:
            message = 'An unknown error occurred.'
            break
        }
        if (message) {
          errorNotice(message)
        }
      }
    }
  }, [])

  function reset() {
    setIsPause(initialPause)
    isPlaying.current = false
    canplay.current = false
    isWaiting.current = false
  }

  useEffect(() => {
    reset()
  }, [id])

  return (
    <div className={classNames(styles.videoControls, className)}>
      <img
        src={isPause ? PlayIcon : PauseIcon}
        className={styles.icon}
        onClick={handlePlay}
      />
      <div className={styles.progressCtn}>
        <div
          className={styles.progress}
          style={{ width: `${(currentTime / duration) * 100}%` }}
        ></div>
      </div>
      <div>
        {getVideoDuration(currentTime)}/{getVideoDuration(duration)}
      </div>
      <img
        src={isFullScreen ? ExitFullScreenIcon : FullScreenIcon}
        className={styles.icon}
        onClick={handleFullScreen}
      />
    </div>
  )
}

export default React.memo(VideoControls)
