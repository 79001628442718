import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import { Typography } from 'antd'
import { CopyGray, CopyPink, CopyYellow } from '@/assets'
import { useEnvInfo } from '@/store/useEnvInfo'
import classNames from 'classnames'

type Props = {
  text: string
  className?: string
}

const TextCopy: React.FC<PropsWithChildren<Props>> = ({
  text,
  className,
  children
}) => {
  const { envInfo } = useEnvInfo()
  return (
    <div className={classNames(styles.textCopy, className)}>
      <Typography.Text
        className={styles.typography}
        copyable={{
          tooltips: false,
          text: text,
          icon: <img src={CopyGray} className={styles.copyIcon} />
        }}
      >
        {children || text}
      </Typography.Text>
    </div>
  )
}

export default React.memo(TextCopy)
