import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import { ButtonProps } from '@/common/types'
import classNames from 'classnames'

type Props = ButtonProps

const ProfileButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <button {...rest} className={classNames(styles.profileButton, className)}>
      {children}
    </button>
  )
}

export default React.memo(ProfileButton)
