import { Theme } from '@/common/types'
import { create } from 'zustand'

type EnvInfo = {
  theme: Theme
}

type State = { envInfo: EnvInfo }

type Actions = {
  save: (info: EnvInfo) => void
  update: (info: Partial<EnvInfo>) => void
  clear: () => void
}

const DEFAULT_INFO: EnvInfo = {
  theme: 'dark'
}

function changeTheme(theme: string) {
  document.documentElement.setAttribute('data-theme', theme)
}

function init() {
  document.documentElement.setAttribute('data-theme', DEFAULT_INFO.theme)
}

init()

const useEnvInfo = create<State & Actions>((set) => ({
  envInfo: DEFAULT_INFO,
  save: async (info) => {
    changeTheme(info.theme || DEFAULT_INFO.theme)
    set({ envInfo: info })
  },
  update: (info) => {
    if (info.theme) {
      changeTheme(info.theme)
    }
    set((state) => {
      return {
        envInfo: {
          ...state.envInfo!,
          ...info
        }
      }
    })
  },
  clear: () => {
    set({ envInfo: DEFAULT_INFO })
  }
}))

export { useEnvInfo }
