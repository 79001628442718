import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import {
  GoBackTitle,
  StepItem,
  CurrencySelect,
  Description
} from '../../components'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { SupportCoin, WalletApi } from '@/services/wallet'
import TextCopy from '@/components/TextCopy'
import { getQrCode, getSearchParams } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import NetworkSelect from '../../components/NetworkSelect'

const RechargeForm: React.FC = () => {
  const { coinCode } = getSearchParams()
  const { t } = useTranslation()
  const [currentStep, setCurrentStep] = useState(1)
  const history = useHistory()
  const [currency, setCurrency] = useState<SupportCoin>()
  const [chainCode, setChainCode] = useState('')
  const [qrCode, setQrCode] = useState<string>()
  const networks = currency?.tokenList ?? []

  const { data, refresh } = useQuery(
    () => WalletApi.getDepositAddress(chainCode),
    [chainCode],
    { manual: true }
  )

  useEffect(() => {
    if (chainCode) {
      refresh()
    }
  }, [chainCode])

  useEffect(() => {
    if (data?.address) {
      getQrCode(data.address).then((url) => setQrCode(url))
    }
  }, [data])

  const token = useMemo(() => {
    return networks.find((n) => n.chainCode === chainCode)
  }, [networks, chainCode])

  return (
    <Module className={styles.recharge}>
      <GoBackTitle
        title={t('wallet.assets.deposit')}
        onClick={history.goBack}
      />
      <div className={styles.steps}>
        <StepItem
          step={1}
          currentStep={currentStep}
          label={t('wallet.currency')}
        >
          <div className={styles.step1}>
            <CurrencySelect
              type="Deposit"
              placeholder={t('wallet.address.selectCoin')}
              coinCode={coinCode}
              value={currency}
              onChange={(data) => {
                setCurrency(data)
                setChainCode('')
                setCurrentStep(2)
              }}
            />
          </div>
        </StepItem>
        <StepItem
          step={2}
          currentStep={currentStep}
          label={t('wallet.address.selectNetWork')}
        >
          <div className={styles.step1}>
            <NetworkSelect
              width={953}
              placeholder={t('wallet.address.selectNetWork')}
              networks={networks}
              value={token?.chainId}
              onChange={(v) => {
                setChainCode(networks.find((n) => n.chainId === v)!.chainCode)
                setCurrentStep(3)
              }}
            />
          </div>
        </StepItem>
        <StepItem
          step={3}
          currentStep={currentStep}
          label={t('wallet.assets.depositAddress')}
          isLast
        >
          <div className={styles.step3}>
            <img src={qrCode} className={styles.qrCode} />
            <div className={styles.addressLabel}>{t('normal.address')}</div>
            <div className={styles.address}>
              <TextCopy text={data?.address ?? ''}>{data?.address}</TextCopy>
            </div>
            <div>{t('wallet.assets.depositAddressSendTip')}</div>
            <div className={styles.items}>
              <Description
                label={t('wallet.assets.minimumDeposit')}
                desc={`${token?.minDepositAmount ?? 0} ${currency?.coinCode ?? ''}`}
              />
              <Description
                label={t('wallet.assets.depositArrival')}
                desc={`${token?.safeBlockNo ?? 0}${t('wallet.assets.networkConfirmation')}`}
              />
              <Description
                label={t('wallet.assets.withdrawUnlock')}
                desc={`${token?.safeBlockNo ?? 0}${t('wallet.assets.networkConfirmation')}`}
              />
            </div>
          </div>
        </StepItem>
      </div>
    </Module>
  )
}

export default React.memo(RechargeForm)
