import { service } from '../http'
import { PromiseListResponse, PromiseResponse, replaceToV2 } from '../common'
import {
  Authenticator,
  FundPasswordRule,
  GenerateGoogleAuthenticatorKeyParams,
  GetNFTDetailParams,
  GetNFTListParams,
  NFT,
  NFTDetail,
  OpenBlindBoxParams,
  SetFundPasswordParams
} from './model'
import { EncryptData } from '../MediaAPI'

const BASE_URL = '/user/api/v1'

export class UserAPI {
  // 分页查询用户NFT列表
  static async getNFTList(
    params: GetNFTListParams
  ): PromiseListResponse<{ records: NFT[] }> {
    return service.post(replaceToV2(`${BASE_URL}/nft/list`), params)
  }
  // 获取我的NFT详情
  static async getNFTDetail(
    params: GetNFTDetailParams
  ): PromiseResponse<NFTDetail> {
    return service.get(`${BASE_URL}/nft/getDetail`, { params })
  }
  // 手动开启盲盒
  static async openBlindBox(
    params: OpenBlindBoxParams
  ): PromiseResponse<NFT[]> {
    return service.post(`${BASE_URL}/nft/openBlindBox`, params)
  }
  // 设置资金密码
  static async setFundPassword(
    params: SetFundPasswordParams
  ): PromiseResponse<void> {
    return service.post(`${BASE_URL}/security/setFundPassword`, params, {
      headers: {
        needEncrypt: '1'
      }
    })
  }
  // 获取google验证器列表
  static async getGoogleAuthenticators(): PromiseResponse<Authenticator[]> {
    return service.get(`${BASE_URL}/security/googleAuthenticator/list`)
  }
  static async generateGoogleAuthenticatorKey(
    params: GenerateGoogleAuthenticatorKeyParams
  ): PromiseResponse<EncryptData> {
    return service.post(
      `${BASE_URL}/security/googleAuthenticator/generateKey`,
      params,
      {
        headers: {
          needEncrypt: '1'
        }
      }
    )
  }
  // 获取资金密码规则
  static async getFundPasswordRule(): PromiseResponse<FundPasswordRule> {
    return service.get(`${BASE_URL}/security/getFundPasswordRule`)
  }
  static async bindGoogleAuthenticator(
    params: GenerateGoogleAuthenticatorKeyParams & { update?: boolean }
  ): PromiseResponse<EncryptData> {
    return service.post(
      `${BASE_URL}/security/googleAuthenticator/bind`,
      params,
      {
        headers: {
          needEncrypt: '1'
        }
      }
    )
  }
  static async unbindGoogleAuthenticator(
    params: GenerateGoogleAuthenticatorKeyParams
  ): PromiseResponse<EncryptData> {
    return service.post(
      `${BASE_URL}/security/googleAuthenticator/unbind`,
      params,
      {
        headers: {
          needEncrypt: '1'
        }
      }
    )
  }
  // 获取用户资金面码和google绑定是否绑定
  static async getSecurityInfo(): PromiseResponse<EncryptData> {
    return service.get(`${BASE_URL}/security/securityInfo`)
  }
}
