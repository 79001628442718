import { useCallback, useContext } from 'react'
import { Context } from '../contexts/Modals'
import type { ModalContentProps, ModalProps } from '@/common/types'

const useModal = <T extends ModalContentProps>(
  modalContent: React.ReactNode,
  modalProps?: ModalProps
) => {
  const { onDismiss, onPresent } = useContext(Context)

  const handlePresent = useCallback(
    (childrenProps?: T) => {
      onPresent(modalContent, childrenProps, modalProps)
    },
    [modalProps, modalContent, onPresent]
  )

  return [handlePresent, onDismiss]
}

export default useModal

export const useConfirmModal = () => useContext(Context)
