import React, { useState } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { DAZZLE_MANAGE } from '@/constants/profile'
import { Space } from 'antd'
import { TextButton } from '@/components/Button'
import Module from '@/components/Module'
import Empty from '@/components/Empty'
import { getUserDrafts, removeDraft } from '@/common/dazzle'
import DraftPreview from './DraftPreview'
import { useTranslation } from 'react-i18next'
import Radio from '../components/Radio'
import { useModal } from '@/store/useModal'
import ConfirmModal from '@/components/ConfirmModal'

const Draft: React.FC = () => {
  const [editing, setEditing] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const { t } = useTranslation()

  const { openAsync } = useModal()

  function selectHandle(id: string) {
    if (selected.includes(id)) {
      setSelected((list) => list.filter((item) => item !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const drafts = getUserDrafts()

  async function handleRemove() {
    const confirmed = await openAsync(
      <ConfirmModal title={t('normal.delete')}>
        {t('dazzle.deleteDraftTip')}
      </ConfirmModal>
    )
    if (confirmed) {
      removeDraft(selected)
      setSelected([])
    }
  }

  const allSelected = drafts.length > 0 && drafts.length === selected.length

  function handleSelectAll() {
    if (allSelected) {
      setSelected([])
    } else {
      setSelected(drafts.map((draft) => draft.id))
    }
  }

  return (
    <Page navigator={DAZZLE_MANAGE}>
      <Module>
        <div className={styles.header}>
          <GoBackTitle title={t('dazzle.draftBox')} />
          {editing ? (
            <Space size={26}>
              <div onClick={handleSelectAll} className={styles.selectAllCtn}>
                <Radio checked={allSelected} />
                <span>{t('dazzle.selectAll')}</span>
              </div>
              <TextButton
                className={styles.textBtn}
                onClick={handleRemove}
                disabled={selected.length === 0}
              >
                {t('normal.delete')}
              </TextButton>
              <TextButton
                className={styles.textBtn}
                onClick={() => {
                  setEditing(false)
                  setSelected([])
                }}
              >
                {t('normal.cancel')}
              </TextButton>
            </Space>
          ) : (
            <TextButton
              className={styles.textBtn}
              onClick={() => setEditing(true)}
            >
              {t('normal.select')}
            </TextButton>
          )}
        </div>
        <Empty data={drafts}>
          <div className={styles.list}>
            {drafts.map((draft) => {
              return (
                <DraftPreview
                  info={draft}
                  key={draft.id}
                  editing={editing}
                  selected={selected}
                  onSelect={selectHandle}
                />
              )
            })}
          </div>
        </Empty>
      </Module>
    </Page>
  )
}

export default React.memo(Draft)
