import React from 'react'
import styles from './style.module.less'
import { Pagination, PaginationProps } from 'antd'

type Props = PaginationProps

const StyledPagination: React.FC<Props> = (props) => {
  return (
    <div className={styles.styledPagination}>
      <Pagination {...props} />
    </div>
  )
}

export default React.memo(StyledPagination)
