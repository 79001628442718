import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { InputProps } from '@/common/types'

type Props = InputProps

const Input: React.FC<Props> = ({ className, ...others }) => {
  return <input className={classNames(styles.input, className)} {...others} />
}

export default React.memo(Input)
