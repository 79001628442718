import React, { useState } from 'react'
import styles from './style.module.less'
import { ButtonGroup, TitleModule } from '../../components'
import { useHistory } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import WithdrawTable from '../../components/WithdrawTable'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'

const Tables: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [selectType, setSelectType] = useState(1)

  const { data: withdrawRecord } = useQuery(
    () =>
      WalletApi.getWithdrawRecord({
        pageNo: 1,
        pageSize: 3,
        assetType: 1,
        selectType
      }),
    [selectType]
  )

  const { data: dataSource = [] } = withdrawRecord ?? {}

  return (
    <TitleModule
      className={styles.tables}
      title={t('wallet.assets.withdrawHistory')}
      onClickMore={() => history.push('/profile/currency-record?type=withdraw')}
    >
      <div className={styles.search}>
        <ButtonGroup
          options={[
            { value: 1, label: t('wallet.assets.withdrawal') },
            { value: 2, label: t('wallet.assets.internal') }
          ]}
          value={selectType}
          onChange={(v) => setSelectType(v as number)}
        />
      </div>
      <Empty data={dataSource}>
        <WithdrawTable dataSource={dataSource} hideOrderId />
      </Empty>
    </TitleModule>
  )
}

export default React.memo(Tables)
