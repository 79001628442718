import React, { useState } from 'react'
import LabelInput from '../LabelInput'
import { InputProps } from '@/common/types'
import styles from './style.module.less'
import { EyeInvisible, Eye } from '@/assets'

type Props = {
  label: string
} & InputProps

const PasswordInput: React.FC<Props> = ({ label, ...props }) => {
  const [show, setShow] = useState(false)
  return (
    <LabelInput
      label={label}
      inputExtra={
        <img
          src={show ? Eye : EyeInvisible}
          className={styles.icon}
          onClick={() => setShow((s) => !s)}
        />
      }
      type={show ? 'text' : 'password'}
      {...props}
    />
  )
}

export default React.memo(PasswordInput)
