import { errorNotice } from '@/common/tip'
import { VOTE_DATA } from '@/constants/vote'
import { ActivityApi, Idol } from '@/services/activityAPI'
import { create } from 'zustand'

type State = { idols: Idol[] }

type Actions = {
  getIdols: (activityId: number) => void
}

const useIdols = create<State & Actions>((set, get) => ({
  idols: [],
  async getIdols(accountId) {
    if (!accountId) {
      return
    }
    const res = await ActivityApi.getAllIdolList(VOTE_DATA.activityId)
    if (res.data.code === 0) {
      set({ idols: res.data.data })
    } else {
      errorNotice(res.data.message)
    }
  }
}))

export { useIdols }
