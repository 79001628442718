import { errorNotice } from '@/common/tip'
import { Lang } from '@/common/types'
import { SYSTEM_NOTICE_COOKIE } from '@/constants/keys'
import { MessageApi } from '@/services/messaageAPI'
import { getCookie } from '@/utils/utils'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import SystemNotice from '../SystemNotice'
import useModal from '@/hooks/useModal'
import { useModal as useStoreModal, getRoot } from '@/store/useModal'
import { createPortal } from 'react-dom'
import Modal from './Modal'

const GlobalBroadcast: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [openSystemNotice] = useModal(<SystemNotice />)

  const { modals } = useStoreModal()

  async function checkSystemNotice() {
    const systemNotice = getCookie(SYSTEM_NOTICE_COOKIE)
    if (systemNotice) {
      return
    }

    const res = await MessageApi.getSystemNotice()
    if (res.data.code !== 0) {
      const msg =
        res.data.code === 15007
          ? t('systemNotice.areaNotSupport')
          : res.data.message
      errorNotice(msg)
      return
    }
    const notice = res.data.data ?? {}
    if (Object.keys(notice).length > 0) {
      const title = notice?.noticeTitle[i18n.language as Lang] ?? ''
      const content = notice?.noticeBody[i18n.language as Lang] ?? ''
      openSystemNotice({ title, content })
    }
  }

  useEffect(() => {
    // 系统通知
    checkSystemNotice()
  }, [])

  return (
    <>
      {modals.map((modal) => {
        return createPortal(
          <Modal id={modal.id} maskClosable={modal.config?.maskClosable}>
            {modal.modal}
          </Modal>,
          getRoot()
        )
      })}
    </>
  )
}

export default React.memo(GlobalBroadcast)
