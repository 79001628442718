import React from 'react'
import styles from './style.module.less'
import { Table } from 'antd'
import type { TableProps } from 'antd'

const ProfileTable: React.FC<TableProps<any>> = (props) => {
  return (
    <div className={styles.table}>
      <Table {...props} />
    </div>
  )
}

export default React.memo(ProfileTable)
