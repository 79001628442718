import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import { getMediaSrc } from '@/utils/utils'
import classNames from 'classnames'

type Props = {
  src?: string
  className?: string
}

const ImgPreview: React.FC<PropsWithChildren<Props>> = ({
  src,
  className = '',
  children
}) => {
  const realSrc = getMediaSrc(src)
  return (
    <div className={classNames(styles.imgCtn, className)}>
      <img src={realSrc} className={styles.bg} />
      <img className={styles.img} src={realSrc} />
      {children}
    </div>
  )
}

export default React.memo(ImgPreview)
