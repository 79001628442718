import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LogoImg from '../../../assets/images/logo.png'

const Logo: React.FC = () => {
  return (
    <StyledLogo to={'/'}>
      <img src={LogoImg} alt="" />
    </StyledLogo>
  )
}

const StyledLogo = styled(Link)`
  display: block;
  width: 144px;
  min-width: 144px;
  height: 46px;
  font-size: 0;
  & > img {
    width: 100%;
  }
`

export default Logo
