import React, { useState } from 'react'
import { Page } from '../components'
import { ASSETS } from '@/constants/profile'
import Total from './Total'
import AssetsDetail from './AssetsDetail'

const Assets: React.FC = () => {
  const [showAsset, setShowAsset] = useState(true)
  function toggle() {
    setShowAsset((s) => !s)
  }
  return (
    <Page navigator={ASSETS}>
      <Total showAsset={showAsset} toggle={toggle} />
      <AssetsDetail showAsset={showAsset} />
    </Page>
  )
}

export default React.memo(Assets)
