import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type Props = {
  className?: string
}

const Divider = ({ children, className }: React.PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.divider, className)}>
      <div className={styles.line}></div>
      <div className={styles.children}>{children}</div>
      <div className={styles.line}></div>
    </div>
  )
}

export default Divider
