import i18next from 'i18next'
import React from 'react'
import styled from 'styled-components'

const ChainDescription = () => {
  const t = (text: string) => {
    return i18next.t(text) as string
  }
  return (
    <div>
      <StyledLine>{t('login.networkName')}：BSC TEST</StyledLine>
      <StyledLine>RPC URL：https://bsc-testnet.publicnode.com</StyledLine>
      <StyledLine>{t('login.chainId')}：97</StyledLine>
      <StyledLine>{t('login.currencySymbol')}：tBNB</StyledLine>
    </div>
  )
}

const StyledLine = styled.div`
  margin-bottom: 6px;
  white-space: nowrap;
`

export default ChainDescription
