import React, { useState } from 'react'
import Wrapper from '../Wrapper'
import { ModalProps } from '@/components/Modal'
import LoginForm from './LoginForm'
import ForgetForm from './ForgetForm'
import styles from './style.module.less'

const Login = ({ onDismiss }: ModalProps) => {
  const [isLogin, setLogin] = useState(true)

  const toLogin = () => setLogin(true)
  const toForget = () => setLogin(false)

  return (
    <Wrapper onDismiss={onDismiss}>
      <LoginForm
        onDismiss={onDismiss}
        toForget={toForget}
        className={!isLogin ? styles.hidden : ''}
      />
      <ForgetForm
        onDismiss={onDismiss}
        toLogin={toLogin}
        className={isLogin ? styles.hidden : ''}
      />
    </Wrapper>
  )
}

export default Login
