import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { InputProps } from '@/common/types'
import Input from '@/components/Input'
import { SearchIcon } from '@/assets'

type Props = InputProps & {
  onSearch?: () => void
}

const SearchInput: React.FC<Props> = ({ className, onSearch, ...others }) => {
  return (
    <div className={classNames(styles.searchInput, className)}>
      <Input className={styles.input} {...others} />
      <img src={SearchIcon} className={styles.icon} onClick={onSearch} />
    </div>
  )
}

export default React.memo(SearchInput)
