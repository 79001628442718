import React, { useState } from 'react'
import styles from './style.module.less'
import { useUserInfo } from '@/store/useUserInfo'
import { IMG_BASE_URL } from '@/config'
import {
  DiscordBind,
  DiscordUnbind,
  TwitterBind,
  TwitterUnbind
} from '@/views/Profile/image'
import { Space } from 'antd'
import { useHistory } from 'react-router-dom'
import { CameraIcon, NoLoginImg } from '@/assets'
import { useTranslation } from 'react-i18next'
import { useMediaInfo } from '@/store/useMediaInfo'
import TextCopy from '@/components/TextCopy'
import AvatarUpload from '../AvatarUpload'

const BaseInfo: React.FC = () => {
  const { info, userAvatar } = useUserInfo()
  const { t } = useTranslation()
  const { mediaInfo } = useMediaInfo()

  const avatar = userAvatar ? `${IMG_BASE_URL}/${userAvatar}` : NoLoginImg
  return (
    <div className={styles.baseInfo}>
      <AvatarUpload>
        <div className={styles.avatarCtn}>
          <img src={avatar} className={styles.img} />
          <img src={CameraIcon} className={styles.camera} />
        </div>
      </AvatarUpload>
      <div className={styles.right}>
        <span className={styles.upload}>{info?.userName}</span>
        <div className={styles.id}>
          <span>{t('profile.userId')}</span>
          {info?.uid && (
            <TextCopy text={`${info.uid}`} className={styles.copy} />
          )}
        </div>
        <Space size={10}>
          <img
            src={mediaInfo?.twitterToken ? TwitterBind : TwitterUnbind}
            className={styles.icon}
          />
          <img
            src={mediaInfo?.discordToken ? DiscordBind : DiscordUnbind}
            className={styles.icon}
          />
        </Space>
      </div>
    </div>
  )
}

export default React.memo(BaseInfo)
