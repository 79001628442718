import React from 'react'
import styles from './style.module.less'
import { ButtonProps } from '@/common/types'
import classNames from 'classnames'

type Props = {
  src: string
  imgClassName?: string
} & ButtonProps

const IconButton: React.FC<Props> = ({
  src,
  children,
  className,
  imgClassName = '',
  ...rest
}) => {
  return (
    <button {...rest} className={classNames(className, styles.btn)}>
      <img className={classNames(styles.img, imgClassName)} src={src} />
    </button>
  )
}

export default React.memo(IconButton)
