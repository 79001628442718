import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import classNames from 'classnames'

type Props = {
  step: number
  currentStep: number
  label: string
  isLast?: boolean
}

const StepItem: React.FC<PropsWithChildren<Props>> = ({
  step,
  currentStep,
  label,
  children,
  isLast = false
}) => {
  const isActive = currentStep >= step
  const isLineActive = currentStep > step
  return (
    <div className={classNames(styles.stepItem, isActive && styles.active)}>
      <div className={styles.left}>
        <div className={styles.index}>{step}</div>
        {!isLast && (
          <div
            className={classNames(
              styles.line,
              isLineActive && styles.activeLine
            )}
          ></div>
        )}
      </div>
      <div className={styles.right}>
        <div className={styles.label}>{label}</div>
        <div className={styles.child}>{isActive ? children : null}</div>
      </div>
    </div>
  )
}

export default React.memo(StepItem)
