import { EncryptData } from '@/services/MediaAPI'
import { rsaDecrypt } from '@/utils/utils'

export const getJsonData = (jsonStr: unknown) => {
  try {
    if (typeof jsonStr === 'object') {
      return jsonStr
    }
    if (typeof jsonStr === 'string' && jsonStr.length) {
      return JSON.parse(jsonStr)
    }
  } catch (e) {
    console.log('getJsonData error', e)
  }
  return null
}

export const decryptData = (data: EncryptData) => {
  if (data.hasEncryption) {
    return getJsonData(rsaDecrypt(data.content) || null)
  }
  return data.content
}
