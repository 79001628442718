import React from 'react'
import styles from './style.module.less'
import { BoxDarkIcon, BoxIcon, LayerDarkIcon, LayerIcon } from '@/assets'
import classNames from 'classnames'

type Props = {
  open: boolean
  onClick: () => void
  className?: string
}

const Switch: React.FC<Props> = ({ open, onClick, className = '' }) => {
  return (
    <div className={classNames(styles.switch, className)} onClick={onClick}>
      <div className={classNames(open && styles.active)}>
        <img src={open ? BoxIcon : BoxDarkIcon} className={styles.icon} />
      </div>
      <div className={classNames(!open && styles.active)}>
        <img src={open ? LayerDarkIcon : LayerIcon} className={styles.icon} />
      </div>
    </div>
  )
}

export default React.memo(Switch)
