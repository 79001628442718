import React, { useMemo, useState } from 'react'
import styles from './style.module.less'
import { Option } from '@/common/types'
import Dropdown from '../Dropdown'
import { DownArrow } from '@/assets'
import classNames from 'classnames'

type Props = {
  label?: string
  options: Option[]
  className?: string
  value?: string | number
  onChange?: (val: string | number) => void
  placeholder?: string
}

const Select: React.FC<Props> = ({
  label,
  options,
  className = '',
  value,
  onChange,
  placeholder
}) => {
  const [open, setOpen] = useState(false)
  const selected = useMemo(() => {
    return options.find((option) => option.value === value)?.label
  }, [value, options])
  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <Dropdown
        onOpenChange={(open) => setOpen(open)}
        trigger={['click']}
        open={open}
        dropdownRender={() => {
          return (
            <div className={styles.options}>
              {options.map((option) => {
                return (
                  <div
                    key={option.value}
                    className={classNames(
                      styles.option,
                      value === option.value && styles.selectedOption
                    )}
                    onClick={() => {
                      onChange?.(option.value)
                      setOpen(false)
                    }}
                  >
                    {option.label}
                  </div>
                )
              })}
            </div>
          )
        }}
      >
        <div className={styles.select} onClick={() => setOpen((o) => !o)}>
          <div className={selected && styles.selected}>
            {selected ?? placeholder}
          </div>
          <img
            src={DownArrow}
            className={classNames(styles.arrow, open && styles.openedArrow)}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default React.memo(Select)
