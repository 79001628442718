import React from 'react'
import TextCopy from '@/components/TextCopy'
import { ellipsisText, openWindow } from '@/utils/utils'
import { LinkIcon } from '@/assets'

export function getTableCopyText(text: string, ellipsis = true, link = '') {
  return text ? (
    <TextCopy text={text}>
      {ellipsis ? ellipsisText(text) : text}
      {link ? (
        <img
          onClick={() => openWindow(link)}
          src={LinkIcon}
          style={{ width: 17, marginLeft: 4, cursor: 'pointer' }}
        />
      ) : null}
    </TextCopy>
  ) : (
    '-'
  )
}

export function getDayStart(timestamp?: number) {
  if (typeof timestamp === 'undefined') {
    return undefined
  }
  return new Date(timestamp).setHours(0, 0, 0, 0)
}

export function getDayEnd(timestamp?: number) {
  if (typeof timestamp === 'undefined') {
    return undefined
  }
  return new Date(timestamp).setHours(23, 59, 59, 999)
}
