import React, { useEffect } from 'react'
import styles from './style.module.less'
import ModalWrapper from '../ModalWrapper'
import { ModalProps } from '../Modal'
import { useUserInfo } from '@/store/useUserInfo'
import { useTranslation } from 'react-i18next'
import { errorNotice } from '@/common/tip'
import { DEVICE_TYPE } from '@/config'

const domain = process.env.isTestOrDev
  ? 'http://34.87.135.238:1085'
  : '//spin.meet48.xyz'

const LotteryModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { info } = useUserInfo()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    function handleEvent(event: any) {
      if (!info) {
        return
      }
      if (event.data === 'token expired') {
        errorNotice('token expired')
        onDismiss?.()
      }
      if (event.data === 'close') {
        onDismiss?.()
      }
    }
    window.addEventListener('message', handleEvent)
    return () => {
      window.removeEventListener('message', handleEvent)
    }
  }, [info?.accountId])

  return (
    <iframe
      className={styles.lotteryIframe}
      src={`${domain}?token=${encodeURIComponent(info?.token ?? '')}&lang=${encodeURIComponent(i18n.language)}&deviceType=${DEVICE_TYPE}`}
    ></iframe>
  )
}

export default React.memo(LotteryModal)
