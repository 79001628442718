import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { Space } from 'antd'
import { DownArrow, Eye, EyeInvisible } from '@/assets'
import { Button } from '@/components/Button'
import { useHistory } from 'react-router-dom'
import { AssetDetailItem, WalletApi } from '@/services/wallet'
import useQuery from '@/hooks/useQuery'
import { useTranslation } from 'react-i18next'

type Props = {
  showAsset: boolean
  toggle: () => void
}

const Total: React.FC<Props> = ({ showAsset, toggle }) => {
  const { t } = useTranslation()
  const [currentAsset, setCurrentAsset] = useState<AssetDetailItem>()

  const { data: assetData } = useQuery(WalletApi.getAssetTotal)

  async function handleSelect(toCoinCode: string) {
    setCurrentAsset(
      assetData!.quotes.find((item) => item.toCoinCode === toCoinCode)
    )
  }

  useEffect(() => {
    if ((assetData?.quotes.length ?? 0) > 0) {
      const usdt = assetData!.quotes.find((item) => item.toCoinCode === 'USDT')
      setCurrentAsset(usdt || assetData!.quotes[0])
    }
  }, [assetData])

  const history = useHistory()

  return (
    <div className={styles.info}>
      <div className={styles.titleCtn}>
        <span className={styles.title}>{t('wallet.assets.estTotalValue')}</span>
        <img
          src={showAsset ? Eye : EyeInvisible}
          className={styles.eyeIcon}
          onClick={toggle}
        />
      </div>
      <div>
        <span className={styles.balance}>
          {showAsset ? currentAsset?.availableValuation ?? 0 : '****'}
        </span>
        {!!assetData && (
          <div className={styles.unitCtn}>
            <span>{currentAsset?.toCoinCode}</span>
            <img src={DownArrow} className={styles.arrowIcon} />
            <div className={styles.list}>
              {assetData.quotes.map((item) => {
                return (
                  <div
                    key={item.toCoinCode}
                    onClick={() => handleSelect(item.toCoinCode)}
                    className={styles.item}
                  >
                    {item.toCoinCode}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
      <div className={styles.dollar}>
        {showAsset ? `≈$${assetData?.availableUSDValuation ?? 0}` : '****'}
      </div>
      <Space size={15}>
        <Button
          className={styles.btn}
          onClick={() => history.push('/profile/recharge')}
        >
          {t('wallet.assets.deposit')}
        </Button>
        <Button
          className={styles.btn}
          onClick={() => history.push('/profile/withdraw')}
        >
          {t('wallet.assets.withdraw')}
        </Button>
      </Space>
    </div>
  )
}

export default React.memo(Total)
