import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import Navigator from '../Navigator'
import Footer from '@/components/Footer'

type Props = {
  navigator?: string
}

const Page: React.FC<PropsWithChildren<Props>> = ({ navigator, children }) => {
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <Navigator navigator={navigator} />
        <div className={styles.main}>{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default React.memo(Page)
