import { http, createConfig } from 'wagmi'
import { opBNBTestnet, opBNB } from 'wagmi/chains'
import { metaMask, injected } from 'wagmi/connectors'

const connectors = [
  metaMask({
    extensionOnly: true,
    enableAnalytics: false
  }),
  injected({
    target() {
      return {
        id: 'okx-wallet',
        name: 'OKX Wallet',
        shimDisconnect: true,
        provider: window.okxwallet
      }
    }
  })
]

const testConfig = createConfig({
  chains: [opBNBTestnet],
  transports: {
    [opBNBTestnet.id]: http()
  },
  connectors
})

const prodConfig = createConfig({
  chains: [opBNB],
  transports: {
    [opBNB.id]: http()
  },
  connectors
})

export const config = process.env.isTestOrDev ? testConfig : prodConfig
