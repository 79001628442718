import React, { useEffect, useState } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { SETTING } from '@/constants/profile'
import { useHistory } from 'react-router-dom'
import Module from '@/components/Module'
import InputBox from '@/components/InputBox'
import { Button, ProfileButton } from '@/components/Button'
import { Form } from 'antd'
import { useUserInfo } from '@/store/useUserInfo'
import useSendEmail from '@/hooks/useSendEmail'
import { errorNotice, notice } from '@/common/tip'
import { useTranslation } from 'react-i18next'
import { UserAPI } from '@/services/userAPI'
import useQuery from '@/hooks/useQuery'
import { getLangData } from '@/utils/utils'
import { decryptData } from '@/common/dataConvert'

const { LabelInput, PasswordInput } = InputBox

const SetFundsPassword: React.FC = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { info } = useUserInfo()
  const { t } = useTranslation()
  const { sendEmail, sendAble, sendText } = useSendEmail({ type: '8' })
  const { data: rule } = useQuery(UserAPI.getFundPasswordRule)

  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    async function fetch() {
      const res = await UserAPI.getSecurityInfo()
      if (res.data.code === 0) {
        const data = decryptData(res.data.data)
        if (data.bindFundPassword && !data.bindGoogleAuthenticator) {
          notice(t('wallet.form.googleNotBindTip'))
          history.goBack()
          return
        }
        setIsUpdate(data.bindFundPassword)
      } else {
        errorNotice(res.data.message)
      }
    }
    fetch()
  }, [])

  async function submit() {
    const values = form.getFieldsValue()
    if (!info) {
      notice(t('task.logFirst'))
      return
    }
    if (!rule) {
      notice(t('Fail to get transaction password format'))
      return
    }
    if (!values.emailCode) {
      notice(t('wallet.form.emailCodeTip'))
      return
    }
    const reg = new RegExp(rule.regular)
    if (!reg.test(values.fundPassword)) {
      notice(getLangData(rule.ruleDescription))
      return
    }
    if (!values.confirmPassword) {
      notice(t('wallet.form.confirmPasswordTip'))
      return
    }
    if (values.fundPassword !== values.confirmPassword) {
      notice(t('wallet.form.passwordNotMatchTip'))
      return
    }
    if (isUpdate && !values.authCode) {
      notice(t('wallet.form.googleTip'))
      return
    }
    const res = await UserAPI.setFundPassword({ ...values })
    if (res.data.code === 0) {
      notice(t('normal.saveSuccess'), 'success')
      history.goBack()
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <Page navigator={SETTING}>
      <Module>
        <GoBackTitle
          title={t('wallet.verify.fundPassword')}
          onClick={history.goBack}
        />
        <Form form={form} onFinish={submit}>
          <div className={styles.form}>
            <Form.Item noStyle name="email" initialValue={info?.email}>
              <LabelInput
                label={t('wallet.verify.emailVerification')}
                disabled
                inputExtra={
                  <ProfileButton
                    className={styles.settingBtn}
                    disabled={!sendAble}
                    onClick={(e) => {
                      e.preventDefault()
                      sendEmail(info?.email ?? '', true)
                    }}
                  >
                    {sendText}
                  </ProfileButton>
                }
              />
            </Form.Item>
            <Form.Item noStyle name="emailCode">
              <LabelInput
                label={t('wallet.verify.emailCode')}
                placeholder={t('wallet.verify.emailPlaceholder')}
              />
            </Form.Item>
            <Form.Item noStyle name="fundPassword">
              <PasswordInput
                label={t('wallet.verify.fundPassword')}
                placeholder={t('wallet.verify.fundPasswordPlaceholder')}
              />
            </Form.Item>
            <Form.Item noStyle name="confirmPassword">
              <PasswordInput
                label={t('wallet.verify.confirmFundPassword')}
                placeholder={t('wallet.verify.confirmFundPasswordPlaceholder')}
              />
            </Form.Item>
            {isUpdate && (
              <Form.Item noStyle name="authCode">
                <PasswordInput
                  label={t('wallet.verify.googleAuthenticator')}
                  placeholder={t(
                    'wallet.verify.googleAuthenticatorPlaceholder'
                  )}
                />
              </Form.Item>
            )}
            <Button className={styles.save} type="submit">
              {t('normal.save')}
            </Button>
          </div>
        </Form>
      </Module>
    </Page>
  )
}

export default React.memo(SetFundsPassword)
