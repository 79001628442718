import React from 'react'
import styles from './style.module.less'
import { Space } from 'antd'
import { DeleteIcon, EditIcon, NoLoginImg } from '@/assets'
import { useHistory } from 'react-router-dom'
import { ProfileButton } from '@/components/Button'
import InputBox from '@/components/InputBox'
import { useModal } from '@/store/useModal'
import UnbindModal from '../UnbindModal'
import useQuery from '@/hooks/useQuery'
import { UserAPI } from '@/services/userAPI'
import { formatTime } from '@/utils/utils'
import { useSafeVerification } from '@/store/useSafeVerification'
import { SafeVerificationParams } from '@/services/wallet'
import { errorNotice, notice } from '@/common/tip'
import { useTranslation } from 'react-i18next'

const { LabelInput } = InputBox

const GoogleAuthenticator: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { openAsync } = useModal()
  const { data: list = [] } = useQuery(UserAPI.getGoogleAuthenticators)
  const bound = list.length > 0

  const { setCallback, clearParams, setParams } = useSafeVerification()

  function handleDelete() {
    setCallback(
      async (params: SafeVerificationParams) => {
        const confirm = await openAsync(<UnbindModal />)
        if (confirm) {
          const res = await UserAPI.unbindGoogleAuthenticator(params)
          if (res.data.code === 0) {
            notice(t('normal.deleteSuccess'), 'success')
            history.push('/profile/setting')
          } else {
            errorNotice(res.data.message)
          }
        }
      },
      { title: 'wallet.verify.unbindAuthenticator', back: true }
    )
    history.push('/profile/safety-verification')
  }

  function handleEdit() {
    setCallback(
      async (params: SafeVerificationParams) => {
        const res = await UserAPI.generateGoogleAuthenticatorKey(params)
        if (res.data.code !== 0) {
          errorNotice(res.data.message)
          return
        }
        setParams(res.data.data)
        history.push(`/profile/set-authenticator`)
      },
      { title: 'wallet.verify.updateAuthenticator', back: true }
    )
    clearParams()
    history.push('/profile/safety-verification')
  }

  return (
    <div className={styles.component}>
      {bound ? (
        <div>
          <div className={styles.label}>
            {t('wallet.verify.linkGoogleAuthenticator')}
          </div>
          <div className={styles.content}>
            <div className={styles.left}>
              <Space size={14}>
                <img src={NoLoginImg} className={styles.avatar} />
                <div className={styles.info}>
                  <div className={styles.name}>{list[0]?.name}</div>
                  <div>
                    {list[0]
                      ? formatTime(list[0].createTime, { format: 'YYYY/MM/DD' })
                      : ''}
                  </div>
                </div>
              </Space>
            </div>
            <Space size={30}>
              <img
                src={EditIcon}
                className={styles.icon}
                onClick={handleEdit}
              />
              <img
                src={DeleteIcon}
                className={styles.icon}
                onClick={handleDelete}
              />
            </Space>
          </div>
        </div>
      ) : (
        <LabelInput
          label={t('wallet.verify.linkGoogleAuthenticator')}
          type="password"
          placeholder={t('wallet.verify.notSet')}
          disabled
          inputExtra={
            <ProfileButton
              className={styles.settingBtn}
              onClick={() => history.push('/profile/set-authenticator')}
            >
              {t('profile.setting')}
            </ProfileButton>
          }
        />
      )}
    </div>
  )
}

export default React.memo(GoogleAuthenticator)
