import React, { useEffect, useMemo } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { ASSETS } from '@/constants/profile'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { Space } from 'antd'
import { Button } from '@/components/Button'
import Tables from './Tables'
import { getSearchParams } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import { decryptData } from '@/common/dataConvert'

const CurrencyDetail: React.FC = () => {
  const { t } = useTranslation()
  const { coinCode } = getSearchParams()
  const { data: supportList = [], refresh: getSupportList } = useQuery(
    WalletApi.getCoinSupportList
  )

  const history = useHistory()
  function to(path: string) {
    history.push(path)
  }

  const { data: originData, refresh } = useQuery(() =>
    WalletApi.getAssetList({
      coinCode,
      pageSize: 1,
      pageNo: 1
    })
  )

  const data = useMemo(() => {
    if (!originData) {
      return null
    }
    const d = decryptData(originData)
    return d.records.length === 0 ? null : d.records[0]
  }, [originData])

  useEffect(() => {
    const timer = setInterval(() => {
      refresh()
      getSupportList()
    }, 5000)
    return () => clearInterval(timer)
  }, [])

  const coin = useMemo(() => {
    return supportList.find((item) => item.coinCode === coinCode)
  }, [supportList, coinCode])

  return (
    <Page navigator={ASSETS}>
      <Module>
        <GoBackTitle onClick={history.goBack} title={coinCode} />
        <div className={styles.header}>
          <div className={styles.left}>
            <img src={data?.coverImage} className={styles.icon} />
            <div className={styles.amount}>{data?.available ?? 0}</div>
            <div>{coinCode}</div>
          </div>
          <Space size={15}>
            {coin?.depositEnable === 1 && (
              <Button
                className={styles.btn}
                onClick={() => to(`/profile/recharge?coinCode=${coinCode}`)}
              >
                {t('wallet.assets.deposit')}
              </Button>
            )}
            {coin?.withdrawEnable === 1 && (
              <Button
                className={styles.btn}
                onClick={() => to(`/profile/withdraw?coinCode=${coinCode}`)}
              >
                {t('wallet.assets.withdraw')}
              </Button>
            )}
            {/* <Button
              className={styles.btn}
              onClick={() => to('/profile/recharge')}
            >
              购买数字货币
            </Button> */}
          </Space>
        </div>
      </Module>
      <Tables supportList={supportList} />
    </Page>
  )
}

export default React.memo(CurrencyDetail)
