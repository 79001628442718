import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import { NoDataDark } from '@/assets'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

type Props = {
  data: any[]
  className?: string
  minHeight?: number
}

const Empty: React.FC<PropsWithChildren<Props>> = ({
  data,
  children,
  className,
  minHeight
}) => {
  const { t } = useTranslation()
  const isEmpty = data.length === 0
  return (
    <>
      <div
        className={classNames(
          styles.empty,
          !isEmpty && styles.hidden,
          className
        )}
        style={{ minHeight }}
      >
        <img src={NoDataDark} className={styles.noDataImg} />
        <div>{t('normal.noData')}</div>
      </div>
      <div className={classNames(isEmpty && styles.hidden)}>{children}</div>
    </>
  )
}

export default React.memo(Empty)
