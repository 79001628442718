import React, { useState } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { Button, TextButton } from '@/components/Button'
import { SETTING } from '@/constants/profile'
import InputBox from '@/components/InputBox'
import Module from '@/components/Module'
import { useUserInfo } from '@/store/useUserInfo'
import useSendEmail from '@/hooks/useSendEmail'
import { errorNotice, notice } from '@/common/tip'
import { useTranslation } from 'react-i18next'
import { rsaEncrypt } from '@/utils/utils'
import { WalletApi } from '@/services/wallet'

const { LabelInput } = InputBox

const UpdateEmail: React.FC = () => {
  const { info, saveUserInfo } = useUserInfo()
  const [newEmail, setNewEmail] = useState('')
  const { t } = useTranslation()

  const [code, setCode] = useState('')
  const [newEmailCode, setNewEmailCode] = useState('')

  const { sendEmail, sendAble, sendText } = useSendEmail({ type: '3' })
  const {
    sendEmail: sendToNewEmail,
    sendAble: newEmailSendAble,
    sendText: newEmailSendText
  } = useSendEmail({
    type: '4'
  })

  const updateEmail = async () => {
    const res = await WalletApi.updateAccountEmail({
      accountId: info!.accountId,
      newMail: rsaEncrypt(newEmail) as string,
      newMailCode: newEmailCode,
      oldMail: rsaEncrypt(info!.email) as string,
      oldMailCode: code
    })
    if (res.data.code === 0) {
      saveUserInfo({ ...info!, email: newEmail })
      notice(t('personal.updateSuccess'), 'success')
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <Page navigator={SETTING}>
      <Module>
        <GoBackTitle title={t('personal.updateEmail')} />
        <div className={styles.form}>
          <LabelInput
            label={t('profile.email')}
            value={info?.email}
            disabled
            inputExtra={
              <TextButton
                className={styles.send}
                onClick={() => sendEmail(info!.email, true)}
                disabled={!sendAble}
              >
                {sendText}
              </TextButton>
            }
          />
          <LabelInput
            label={t('profile.verificationCode')}
            placeholder={t('personal.emailTip')}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <LabelInput
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            label={t('profile.newEmail')}
            placeholder={t('personal.emailNew')}
            inputExtra={
              <TextButton
                className={styles.send}
                onClick={() => sendToNewEmail(newEmail, false)}
                disabled={!newEmailSendAble}
              >
                {newEmailSendText}
              </TextButton>
            }
          />
          <LabelInput
            label={t('profile.verificationCode')}
            placeholder={t('personal.emailTip')}
            value={newEmailCode}
            onChange={(e) => setNewEmailCode(e.target.value)}
          />
        </div>
        <Button className={styles.btn} onClick={updateEmail}>
          {t('personal.verifyBtn')}
        </Button>
      </Module>
    </Page>
  )
}

export default React.memo(UpdateEmail)
