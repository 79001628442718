import { errorNotice } from '@/common/tip'
import { IMG_BASE_URL } from '@/config'
import { CommonApi, LatestVersionDownloadURLs } from '@/services/commonAPI'
import { create } from 'zustand'

type State = { urls: LatestVersionDownloadURLs }

type Actions = {
  getURLs: () => Promise<void>
}

const DEFAULT_URLS: LatestVersionDownloadURLs = {
  appStoreUrl: '',
  appStoreQrUrl: '',
  googlePlayUrl: '',
  googlePlayQrUrl: '',
  apkDownloadUrl: '',
  apkDownloadQrUrl: ''
}

const useDownload = create<State & Actions>((set) => ({
  urls: DEFAULT_URLS,
  async getURLs() {
    const res = await CommonApi.getLatestVersionDownloadUrl()
    if (res.data.code === 0) {
      const { data } = res.data
      const convertedData = {
        ...data,
        appStoreQrUrl: `${IMG_BASE_URL}/${data.appStoreQrUrl}`,
        googlePlayQrUrl: `${IMG_BASE_URL}/${data.googlePlayQrUrl}`,
        apkDownloadUrl: `${IMG_BASE_URL}/${data.apkDownloadUrl}`,
        apkDownloadQrUrl: `${IMG_BASE_URL}/${data.apkDownloadQrUrl}`
      }
      set({ urls: convertedData })
    } else {
      errorNotice(res.data.message)
    }
  }
}))

export { useDownload }
