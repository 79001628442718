import { errorNotice } from '@/common/tip'
import { Obj } from '@/common/types'
import { PromiseResponse } from '@/services/common'
import { useEffect, useState } from 'react'

type Config = {
  manual: boolean
}

const DEFAULT_CONFIG: Config = {
  manual: false
}

function useQuery<T extends Obj | boolean | number>(
  request: () => PromiseResponse<T>,
  deps: any[] = [],
  config: Config = DEFAULT_CONFIG
) {
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(false)

  async function getData() {
    setLoading(true)
    const res = await request()
    setLoading(false)
    if (res.data.code === 0) {
      setData(res.data.data)
    } else {
      errorNotice(res.data.message)
    }
  }

  useEffect(() => {
    if (!config.manual) {
      getData()
    }
  }, [...deps, config])

  function refresh() {
    getData()
  }

  return { data, refresh, loading }
}

export default useQuery
