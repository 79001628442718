import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import InputBox from '@/components/InputBox'
import { Button, TextButton } from '@/components/Button'
import { SETTING } from '@/constants/profile'
import { noop, useSafeVerification } from '@/store/useSafeVerification'
import { useUserInfo } from '@/store/useUserInfo'
import { Form } from 'antd'
import useSendEmail from '@/hooks/useSendEmail'
import { useHistory } from 'react-router-dom'
import { notice } from '@/common/tip'
import Module from '@/components/Module'
import { getClipboardText, getSearchParams } from '@/utils/utils'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import { useTranslation } from 'react-i18next'

const { LabelInput, PasswordInput } = InputBox

const EMAIL_CHECK = 'EMAIL_CHECK'
const FUND_PASSWORD_CHECK = 'FUND_PASSWORD_CHECK'
const GOOGLE_AUTHENTICATOR_CHECK = 'GOOGLE_AUTHENTICATOR_CHECK'

const WithdrawSafetyVerification: React.FC = () => {
  const { t } = useTranslation()
  const { callback, setParams } = useSafeVerification()
  const { info } = useUserInfo()
  const [form] = Form.useForm()
  const history = useHistory()
  const type = getSearchParams().type || '8'
  const [loading, setLoading] = useState(false)
  const { amount, coinId } = getSearchParams()

  const { sendEmail, sendAble, sendText } = useSendEmail({ type })

  const { data: withdrawMethod = [], loading: queryLoading } = useQuery(() =>
    WalletApi.getWithdrawVerificationMethod({ amount, coinId })
  )

  const hasEmail = useMemo(() => {
    return withdrawMethod.some((item) => item.verificationCode === EMAIL_CHECK)
  }, [withdrawMethod])

  const hasFundPassword = useMemo(() => {
    return withdrawMethod.some(
      (item) => item.verificationCode === FUND_PASSWORD_CHECK
    )
  }, [withdrawMethod])

  const hasGoogle = useMemo(() => {
    return withdrawMethod.some(
      (item) => item.verificationCode === GOOGLE_AUTHENTICATOR_CHECK
    )
  }, [withdrawMethod])

  async function handleOk(e: any) {
    e.preventDefault()
    const { emailCode, authCode, fundPassword } = form.getFieldsValue()
    if (!emailCode && hasEmail) {
      notice(t('wallet.form.emailCodeTip'))
      return
    }
    if (!fundPassword && hasFundPassword) {
      notice(t('wallet.form.fundPasswordTip'))
      return
    }
    if (!authCode && hasGoogle) {
      notice(t('wallet.form.googleTip'))
      return
    }
    const params = { emailCode, authCode, fundPassword }
    setLoading(true)
    const save = await callback(params)
    setLoading(false)
    if (save) {
      setParams(params)
    }
  }

  useEffect(() => {
    if (noop === callback) {
      history.goBack()
    }
  }, [])

  async function handleClip(e: any) {
    e.preventDefault()
    const text = await getClipboardText()
    form.setFieldValue('authCode', text)
  }

  const Email = (
    <>
      <Form.Item noStyle name="email" initialValue={info?.email}>
        <LabelInput
          label={`Step1: ${t('wallet.verify.emailVerification')}`}
          disabled
          inputExtra={
            <TextButton
              className={styles.strong}
              disabled={!sendAble}
              onClick={(e) => {
                e.preventDefault()
                sendEmail(info?.email ?? '', true)
              }}
            >
              {sendText}
            </TextButton>
          }
        />
      </Form.Item>
      <Form.Item noStyle name="emailCode">
        <LabelInput
          label=""
          placeholder={t('wallet.verify.emailPlaceholder')}
          className={styles.verificationCode}
        />
      </Form.Item>
    </>
  )

  const FundPassword = (
    <Form.Item noStyle name="fundPassword">
      <PasswordInput
        label={`Step2: ${t('wallet.verify.fundPassword')}`}
        placeholder={t('wallet.verify.fundPasswordPlaceholder')}
      />
    </Form.Item>
  )

  const Google = (
    <Form.Item noStyle name="authCode">
      <LabelInput
        label={`Step3: ${t('wallet.verify.googleAuthenticator')}`}
        placeholder={t('wallet.verify.googleAuthenticatorPlaceholder')}
        inputExtra={
          <TextButton className={styles.strong} onClick={handleClip}>
            {t('wallet.paste')}
          </TextButton>
        }
        className={styles.step3}
      />
    </Form.Item>
  )

  return (
    <Page navigator={SETTING}>
      <Module>
        <GoBackTitle title={t('wallet.verify.securityVerification')} />
        <div className={styles.desc}>
          {t('wallet.verify.securityVerificationTip')}
        </div>
        <Form form={form}>
          <div className={styles.form}>
            {hasEmail && Email}
            {hasFundPassword && FundPassword}
            {hasGoogle && Google}
            <Button
              className={styles.btn}
              onClick={handleOk}
              disabled={loading || queryLoading}
            >
              {t('normal.ok')}
            </Button>
          </div>
        </Form>
      </Module>
    </Page>
  )
}

export default React.memo(WithdrawSafetyVerification)
