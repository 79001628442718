import React from 'react'
import Logo from './components/Logo'
import NotConnectButton from './components/NotConnectButton'
import AccountButton from './components/AccountButton'
import Navigate from './components/Navigate'
import styles from './style.module.less'
import { useUserInfo } from 'store/useUserInfo'
import { IconDownload } from './images'
import { useHistory } from 'react-router-dom'
import { taEnterPageTrack } from '@/common/thinkingdata'

const Header: React.FC = () => {
  const { info } = useUserInfo()
  const history = useHistory()

  return (
    <div className={styles.header}>
      <div className={styles.main}>
        <div className={styles.left}>
          <Logo />
          <Navigate />
        </div>
        <div className={styles.right}>
          {!info ? <NotConnectButton /> : <AccountButton userInfo={info} />}
          <img
            src={IconDownload}
            className={styles.download}
            onClick={() => {
              history.push('/app')
              taEnterPageTrack('/app', '导航栏')
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default React.memo(Header)
