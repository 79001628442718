import React, { PropsWithChildren } from 'react'
import styles from './style.module.less'
import { DivProps } from '@/common/types'
import { useModal } from '@/store/useModal'

type Props = {
  maskClosable?: boolean
} & DivProps

const Modal: React.FC<PropsWithChildren<Props>> = ({
  children,
  maskClosable = true,
  id
}) => {
  const { destroy } = useModal()
  function handleClick() {
    if (maskClosable) {
      destroy(id)
    }
  }
  return (
    <div className={styles.modal} onClick={handleClick}>
      <div onClick={(e) => e.stopPropagation()} className={styles.children}>
        {children}
      </div>
    </div>
  )
}

export default React.memo(Modal)
