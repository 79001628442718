import React, { useState, useMemo } from 'react'
import styles from './style.module.less'
import { Eye, EyeInvisible } from '@/assets'
import classNames from 'classnames'
import useSendEmail from '@/hooks/useSendEmail'
import { TextButton } from '@/components/Button'

type Props = {
  title: string
  value: string
  onChange: (v: string) => void
  hasSend?: boolean
  isPassword?: boolean
  extra?: React.ReactNode
  onKeyDown?: React.KeyboardEventHandler<Element>
  email?: string
}

const InputBox = ({
  title,
  isPassword = false,
  hasSend = false,
  extra,
  value,
  onChange,
  onKeyDown,
  email = ''
}: Props) => {
  const [showPassword, setShowPassword] = useState(false)

  const [isFocus, setIsFocus] = useState(false)

  const type = useMemo(() => {
    if (!isPassword) {
      return 'text'
    }
    return showPassword ? 'text' : 'password'
  }, [isPassword, showPassword])

  const { sendEmail, sendAble, sendText } = useSendEmail({ type: '2' })

  return (
    <div className={styles.inputBox}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {extra}
      </div>
      <div className={classNames(styles.inputCtn, isFocus && styles.focus)}>
        <input
          type={type}
          className={styles.input}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={() => setIsFocus(false)}
          onFocus={() => setIsFocus(true)}
          onKeyDown={onKeyDown}
        />
        {isPassword && (
          <img
            onClick={() => setShowPassword((v) => !v)}
            src={showPassword ? Eye : EyeInvisible}
            className={styles.eye}
          />
        )}
        {hasSend && (
          <TextButton
            className={classNames(styles.send)}
            onClick={() => sendEmail(email, true)}
            disabled={!sendAble}
          >
            {sendText}
          </TextButton>
        )}
      </div>
    </div>
  )
}

export default InputBox
