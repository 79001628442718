import React from 'react'
import styles from './style.module.less'
import { Page } from '../components'
import { ASSETS } from '@/constants/profile'
import Form from './Form'
import Tables from './Tables'

const Withdraw: React.FC = () => {
  return (
    <Page navigator={ASSETS}>
      <Form />
      <Tables />
    </Page>
  )
}

export default React.memo(Withdraw)
