import React, { CSSProperties, Fragment, useMemo } from 'react'
import styles from './style.module.less'
import { Obj } from '@/common/types'
import { useTranslation } from 'react-i18next'
import NoData from '@/components/NoData'
import type { Column } from '@/common/types'
import classNames from 'classnames'

type Props<T> = {
  columns: Column<T>[]
  dataSource: T[]
  rowKey?: string
  emptyVal?: string
  className?: string
  extraRow?: T
  scrollY?: number
}

function Table<T extends Obj>({
  columns,
  dataSource,
  rowKey = 'id',
  emptyVal = '-',
  className = '',
  extraRow,
  scrollY
}: Props<T>) {
  const { t } = useTranslation()

  const columnsStyle = useMemo(() => {
    return {
      gridTemplateColumns: `repeat(${columns.length}, 1fr)`
    } as CSSProperties
  }, [columns.length])

  const columnEndStyle = useMemo(() => {
    return {
      gridColumnEnd: `span ${columns.length}`
    } as CSSProperties
  }, [columns.length])

  const hasExtraRow = !!(dataSource.length > 0 && extraRow)

  function renderRow(item: T, index: number) {
    return columns.map((column) => {
      const node = column.render?.(item, index, column.dataIndex as string)
      return (
        <div
          key={column.dataIndex as string}
          className={styles.td}
          style={{ width: column.width }}
        >
          {(node ? node : item[column.dataIndex]) ?? emptyVal}
        </div>
      )
    })
  }

  return (
    <div className={classNames(styles.table, className)}>
      <div
        className={styles.thead}
        style={{
          ...columnsStyle,
          ...columnEndStyle
        }}
      >
        {columns.map((column) => {
          return (
            <div
              key={column.dataIndex as string}
              className={styles.th}
              style={{ width: column.width }}
            >
              {column.title}
            </div>
          )
        })}
      </div>
      <div
        className={styles.tbody}
        style={{
          ...columnsStyle,
          ...columnEndStyle,
          overflowY: 'auto',
          maxHeight: scrollY
        }}
      >
        {dataSource.map((item, index) => {
          return (
            <Fragment key={item[rowKey]}>{renderRow(item, index)}</Fragment>
          )
        })}
        {dataSource.length === 0 && (
          <div className={styles.noDataCtn} style={columnEndStyle}>
            <NoData text={t('feedback.nodata')} />
          </div>
        )}
      </div>
      {hasExtraRow && (
        <div
          className={styles.fixedRow}
          style={{
            ...columnsStyle,
            ...columnEndStyle
          }}
        >
          {renderRow(extraRow, -1)}
        </div>
      )}
    </div>
  )
}

export default Table
