import React, { useState } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { Button, TextButton } from '@/components/Button'
import { Eye, EyeInvisible } from '@/assets'
import { SETTING } from '@/constants/profile'
import InputBox from '@/components/InputBox'
import Module from '@/components/Module'
import { useUserInfo } from '@/store/useUserInfo'
import useSendEmail from '@/hooks/useSendEmail'
import { checkPassword } from '@/common/login'
import { WalletApi } from '@/services/wallet'
import { rsaEncrypt } from '@/utils/utils'
import { errorNotice, notice } from '@/common/tip'
import { useTranslation } from 'react-i18next'

const { LabelInput } = InputBox

const UpdatePassword: React.FC = () => {
  const { info } = useUserInfo()
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const { t } = useTranslation()

  const { sendEmail, sendAble, sendText } = useSendEmail({ type: '2' })

  const [visible, setVisible] = useState(false)

  const updatePwd = async () => {
    if (!checkPassword(password, 2)) {
      return
    }
    const res = await WalletApi.updateAccountPassword({
      accountId: info!.accountId,
      mail: rsaEncrypt(info!.email) as string,
      mailCode: code,
      newPassword: rsaEncrypt(password) as string
    })
    if (res.data.code == 0) {
      notice(t('personal.updatePwdSuccess'), 'success')
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <Page navigator={SETTING}>
      <Module>
        <GoBackTitle title={t('personal.updatePwd')} />
        <div className={styles.form}>
          <LabelInput
            label={t('profile.email')}
            value={info?.email}
            disabled
            inputExtra={
              <TextButton
                className={styles.send}
                onClick={() => sendEmail(info!.email, true)}
                disabled={!sendAble}
              >
                {sendText}
              </TextButton>
            }
          />
          <LabelInput
            label={t('profile.verificationCode')}
            placeholder={t('personal.emailTip')}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <LabelInput
            label={t('profile.newPwd')}
            placeholder={t('personal.updatePwdTip')}
            type={visible ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            inputExtra={
              <img
                src={visible ? Eye : EyeInvisible}
                className={styles.eye}
                onClick={() => setVisible((v) => !v)}
              />
            }
          />
        </div>
        <Button className={styles.btn} onClick={updatePwd}>
          {t('personal.verifyBtn')}
        </Button>
      </Module>
    </Page>
  )
}

export default React.memo(UpdatePassword)
