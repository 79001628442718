import React from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { ITEMS_MANAGE } from '@/constants/profile'
import Module from '@/components/Module'
import { useHistory } from 'react-router-dom'
import { WarningIcon } from '@/assets'
import {
  formatTime,
  getLangData,
  getMediaSrc,
  getSearchParams
} from '@/utils/utils'
import useQuery from '@/hooks/useQuery'
import { UserAPI } from '@/services/userAPI'
import { DefaultNFT, DetailBox } from '../image'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
import { errorNotice } from '@/common/tip'

const ItemDetail: React.FC = () => {
  const params = getSearchParams()
  const { data } = useQuery(() =>
    UserAPI.getNFTDetail({
      id: params.id,
      tokenId: params.tokenId
    })
  )

  const history = useHistory()
  const { t } = useTranslation()

  const name = getLangData(data?.goodsNameI18n)
  const src = getMediaSrc(data?.resources[0]?.resourceUrl) || DefaultNFT

  async function handleOpen() {
    const res = await UserAPI.openBlindBox({
      type: 1,
      goodsId: data!.goodsId,
      nftId: data!.id
    })
    if (res.data.code === 0) {
      const nftData = res.data.data[0]
      if (nftData) {
        history.push(
          `/profile/nft-detail?id=${nftData.id}&tokenId=${nftData.tokenId}`
        )
      }
    } else {
      errorNotice(res.data.message)
    }
  }

  return (
    <Page navigator={ITEMS_MANAGE}>
      <Module>
        <div className={styles.header}>
          <GoBackTitle
            title={name}
            onClick={() => history.push('/profile/items')}
          />
          {/* <Button className={styles.btn}>转出NFT</Button> */}
        </div>
        <div className={styles.detail}>
          <div className={styles.posterCtn}>
            <img src={DetailBox} className={styles.locationIcon} />
            <div className={styles.box}>
              <img className={styles.poster} src={src} />
              {data?.openBox === 1 && (
                <Button className={styles.openBtn} onClick={handleOpen}>
                  {t('profile.openBox')}
                </Button>
              )}
            </div>
          </div>
          <div className={styles.infoCtn}>
            <div className={styles.label}>{t('profile.basicInfo')}</div>
            <div className={styles.title}>{name}</div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.redemptionPrice')}</div>
              <div>
                {data ? `${data.exchangePrice} ${t('gifr.pick')}` : '-'}
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.quantity')}</div>
              <div>{data?.quantityHeld ?? '-'}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.redemptionTime')}</div>
              <div>{data ? formatTime(data?.createdTime) : '-'}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>{t('profile.orderId')}</div>
              <div>{data?.orderSn ?? '-'}</div>
            </div>
            {/* <div className={styles.line}>
              <div className={styles.label}>网络</div>
              <div>{chainName ?? '-'}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>合约地址</div>
              <div>{data?.contractAddress ?? '-'}</div>
            </div>
            <div className={styles.line}>
              <div className={styles.label}>代币ID</div>
              <div>{params.tokenId}</div>
            </div> */}
          </div>
        </div>
        <div className={styles.tipCtn}>
          <img src={WarningIcon} className={styles.warningIcon} />
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: getLangData(data?.goodsDescribeI18n)
            }}
          ></div>
        </div>
      </Module>
    </Page>
  )
}

export default React.memo(ItemDetail)
