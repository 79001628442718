import React, { useState } from 'react'
import styles from './style.module.less'
import Dropdown from '@/components/Dropdown'
import { DownArrow } from '@/assets'
import { Radio } from 'antd'
import classNames from 'classnames'
import Select from '@/components/Select'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import { useTranslation } from 'react-i18next'

type Props = {
  label?: string
  className?: string
  placeholder?: string
  value?: string
  onChange?: (val: string) => void
}

type FormProps = {
  onChange?: (val: string) => void
  close: () => void
}

function Form({ onChange, close }: FormProps) {
  const { t } = useTranslation()
  const [sourceType, setSourceType] = useState(1)
  const [sourceCode, setSourceCode] = useState('')
  const { data = [] } = useQuery(
    () => WalletApi.getAddressSource(sourceType),
    [sourceType]
  )

  return (
    <div className={styles.form}>
      <div className={styles.title}>{t('normal.select')}</div>
      <div className={styles.formItem}>
        <div className={styles.formLabel}>
          {t('wallet.address.addressType')}
        </div>
        <Radio.Group
          value={sourceType}
          onChange={(e) => {
            setSourceType(e.target.value)
            setSourceCode('')
          }}
        >
          <Radio value={1}>{t('wallet.address.platformAddress')}</Radio>
          <Radio value={2}>{t('wallet.address.walletAddress')}</Radio>
        </Radio.Group>
      </div>
      <div className={styles.formItem}>
        <Select
          options={data.map((item) => ({
            value: item,
            label: item
          }))}
          label={t('wallet.address.choosePlatform')}
          placeholder={t('normal.select')}
          value={sourceCode}
          onChange={(val) => {
            onChange?.(`${sourceType}_${val}`)
            setSourceCode(`${val}`)
            close()
          }}
        />
      </div>
      <div className={styles.tip}>{t('wallet.address.addressSourceTip')}</div>
    </div>
  )
}

const AddressSelect: React.FC<Props> = ({
  label,
  className,
  placeholder,
  value,
  onChange
}) => {
  const [open, setOpen] = useState(false)

  const text = value ? value.split('_')[1] : value

  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <Dropdown
        onOpenChange={(open) => setOpen(open)}
        trigger={['click']}
        open={open}
        dropdownRender={() => {
          return <Form onChange={onChange} close={() => setOpen(false)} />
        }}
      >
        <div className={styles.select} onClick={() => setOpen((o) => !o)}>
          <div className={value ? styles.selected : ''}>
            {text || placeholder}
          </div>
          <img
            src={DownArrow}
            className={classNames(styles.arrow, open && styles.openedArrow)}
          />
        </div>
      </Dropdown>
    </div>
  )
}

export default React.memo(AddressSelect)
