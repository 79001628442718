import React, { useEffect, useMemo, useState } from 'react'
import styles from './style.module.less'
import { GoBackTitle, Page } from '../components'
import { SETTING } from '@/constants/profile'
import { useHistory } from 'react-router-dom'
import Module from '@/components/Module'
import InputBox from '@/components/InputBox'
import { Button, ProfileButton } from '@/components/Button'
import { UserAPI } from '@/services/userAPI'
import { useUserInfo } from '@/store/useUserInfo'
import { Form } from 'antd'
import { errorNotice, notice } from '@/common/tip'
import useSendEmail from '@/hooks/useSendEmail'
import { getClipboardText, getQrCode } from '@/utils/utils'
import { decryptData } from '@/common/dataConvert'
import TextCopy from '@/components/TextCopy'
import { useSafeVerification } from '@/store/useSafeVerification'
import { EncryptData } from '@/services/MediaAPI'
import { useTranslation } from 'react-i18next'

const { LabelInput } = InputBox

const SetAuthenticator: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { info } = useUserInfo()
  const { params, clearParams } = useSafeVerification()
  const [data, setData] = useState<EncryptData>()

  async function getGoogleAuthenticatorBound() {
    const res = await UserAPI.getSecurityInfo()
    if (res.data.code === 0) {
      const data = decryptData(res.data.data)
      return data.bindGoogleAuthenticator as boolean
    } else {
      errorNotice(res.data.message)
      return
    }
  }

  useEffect(() => {
    async function fetch() {
      if (params) {
        // 正常流程，编辑模式
        setData(params)
      } else {
        const googleBound = await getGoogleAuthenticatorBound()
        if (googleBound === undefined) {
          return
        }
        if (googleBound) {
          // 已绑定，并且没有params，说明此时是通过url直接进入页面
          history.push('/profile/setting')
          return
        } else {
          const res = await UserAPI.generateGoogleAuthenticatorKey({
            emailCode: '',
            authCode: ''
          })
          if (res.data.code === 0) {
            setData(res.data.data)
          } else {
            errorNotice(res.data.message)
          }
        }
      }
    }
    fetch()
  }, [])

  const key = useMemo(() => {
    if (!data) {
      return null
    }
    return decryptData(data)
  }, [data])

  const [form] = Form.useForm()
  const { sendEmail, sendAble, sendText } = useSendEmail({ type: '8' })
  const [qrCode, setQrCode] = useState('')

  async function submit() {
    const values = form.getFieldsValue()
    if (!key) {
      notice(t('Failed get key'))
      return
    }
    if (!values.emailCode) {
      notice(t('wallet.form.emailCodeTip'))
      return
    }
    if (!values.authCode) {
      notice(t('wallet.form.googleTip'))
      return
    }
    const res = await UserAPI.bindGoogleAuthenticator({
      ...values,
      update: !!params
    })
    if (res.data.code === 0) {
      notice(t('normal.bindSuccess'), 'success')
      clearParams()
      history.push('/profile/setting')
    } else {
      errorNotice(res.data.message)
    }
  }

  async function handleClip(e: any) {
    e.preventDefault()
    const text = await getClipboardText()
    form.setFieldValue('authCode', text)
  }

  useEffect(() => {
    if (key) {
      getQrCode(key.secretUri).then((url) => setQrCode(url))
    }
  }, [key])

  return (
    <Page navigator={SETTING}>
      <Module>
        <GoBackTitle
          title={t('wallet.verify.linkGoogleAuthenticator')}
          onClick={history.goBack}
        />
        <div className={styles.desc}>{t('wallet.verify.googleVerifyDesc')}</div>
        <div className={styles.qrCodeCtn}>
          <div className={styles.desc}>
            {t('wallet.verify.googleSetupKeyTip')}
          </div>
          <img src={qrCode} className={styles.qrCode} />
          <div className={styles.keyLabel}>{t('wallet.verify.setupKey')}</div>
          <div className={styles.key}>
            <TextCopy text={key?.secretKey}>{key?.secretKey}</TextCopy>
          </div>
        </div>
        <Form onFinish={submit} form={form}>
          <div className={styles.form}>
            <LabelInput
              label={t('wallet.verify.emailVerification')}
              disabled
              value={info?.email}
              inputExtra={
                <ProfileButton
                  className={styles.settingBtn}
                  disabled={!sendAble}
                  onClick={(e) => {
                    e.preventDefault()
                    sendEmail(info?.email ?? '', true)
                  }}
                >
                  {sendText}
                </ProfileButton>
              }
            />
            <Form.Item noStyle name="emailCode">
              <LabelInput
                label=""
                placeholder={t('wallet.verify.emailPlaceholder')}
              />
            </Form.Item>
            <Form.Item noStyle name="authCode">
              <LabelInput
                label={`${t('wallet.verify.googleAuthenticator')}`}
                placeholder={t('wallet.verify.googleAuthenticatorPlaceholder')}
                inputExtra={
                  <ProfileButton
                    className={styles.settingBtn}
                    onClick={handleClip}
                  >
                    {t('wallet.paste')}
                  </ProfileButton>
                }
              />
            </Form.Item>
            <Button className={styles.save} type="submit">
              {t('wallet.verify.enable')}
            </Button>
          </div>
        </Form>
      </Module>
    </Page>
  )
}

export default React.memo(SetAuthenticator)
