import React from 'react'
import styles from './style.module.less'
import ModalWrapper from '../ModalWrapper'
import { ModalProps } from '../Modal'
import { Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'
import { setCookie } from '@/utils/utils'
import { SYSTEM_NOTICE_COOKIE } from '@/constants/keys'

type Props = {
  title?: string
  content?: string
} & ModalProps

const SystemNotice: React.FC<Props> = ({ title, content = '', onDismiss }) => {
  const { t } = useTranslation()
  function handleChange(checked: boolean) {
    const validTime = checked ? 7 * 86400 * 1000 : -1
    setCookie(SYSTEM_NOTICE_COOKIE, '1', validTime)
  }

  return (
    <ModalWrapper onDismiss={onDismiss} title={title}>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className={styles.content}
      ></div>
      <div className={styles.checkboxCtn}>
        <Checkbox onChange={(e) => handleChange(e.target.checked)}></Checkbox>
        <span className={styles.tip}>{t('systemNotice.weekNotRemind')}</span>
      </div>
    </ModalWrapper>
  )
}

export default React.memo(SystemNotice)
