import React from 'react'
import styles from './style.module.less'
import classNames from 'classnames'
import { ButtonProps } from '@/common/types'

type Props = ButtonProps & {
  borderOnly?: boolean
}

const Button = ({
  children,
  className,
  borderOnly = false,
  ...rest
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      className={classNames(
        borderOnly && styles.borderOnly,
        styles.button,
        className
      )}
      {...rest}
    >
      <div className={borderOnly ? styles.content : ''}>{children}</div>
    </button>
  )
}

export default Button
