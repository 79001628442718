import React from 'react'
import styles from './style.module.less'
import { getMediaSrc } from '@/utils/utils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { FOLDER_SIZE } from '@/constants/dazzle'
import { getUserDrafts } from '@/common/dazzle'
import ImgPreview from '@/components/ImgPreview'

const DraftFolder: React.FC = () => {
  const { t } = useTranslation()

  const drafts = getUserDrafts()

  const lastDraft = drafts[drafts.length - 1]

  const history = useHistory()
  const src = getMediaSrc(lastDraft?.coverImageUrl)

  function clickHandle() {
    history.push('/profile/draft')
  }

  return (
    <div className={styles.draftFolder} onClick={() => clickHandle()}>
      <ImgPreview src={src} className={styles.imgCtn}>
        <div className={styles.info}>
          <span>{t('dazzle.draftBox')}</span>
          <span>
            {drafts.length}/{FOLDER_SIZE}
          </span>
        </div>
      </ImgPreview>
      <div className={styles.title}>
        <span>{t('dazzle.draft')}</span>
        <span className={styles.titleRight}>{t('dazzle.viewDrafts')}</span>
      </div>
    </div>
  )
}

export default React.memo(DraftFolder)
