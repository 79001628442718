import { DAZZLE_DETAIL_LEAVE, useEvents } from '@/store/useEvents'
import { useHistory } from 'react-router-dom'

const useHandleHistory = () => {
  const history = useHistory()
  const { eventsMap, execEvents } = useEvents()

  async function push(path: string) {
    try {
      if (eventsMap[DAZZLE_DETAIL_LEAVE]) {
        await execEvents(DAZZLE_DETAIL_LEAVE)
      }
      history.push(path)
    } catch (e) {
      console.log(e)
    }
  }

  return { push }
}

export default useHandleHistory
