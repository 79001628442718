import React, { useState } from 'react'
import styles from './style.module.less'
import { FileIcon } from '@/assets'
import InputBox from '@/components/InputBox'
import ModalWrapper from '@/components/ModalWrapper'
import { ModalProps } from '@/components/Modal'
import Address from '../Address'
import { useModal } from '@/store/useModal'
import useQuery from '@/hooks/useQuery'
import { Address as AddressType, WalletApi } from '@/services/wallet'
import { Button } from '@/components/Button'
import { useTranslation } from 'react-i18next'
import Empty from '@/components/Empty'

const { LabelInput } = InputBox

type Props = {
  onChange?: (addr: string) => void
  chainId?: number
}

type AddressModalType = {
  id: string
  onChange?: (id: string) => void
  list: AddressType[]
} & ModalProps

const AddressModal: React.FC<AddressModalType> = ({
  onDismiss,
  list,
  id,
  onChange
}) => {
  const { t } = useTranslation()

  const [innerId, setInnerId] = useState(id || '')

  function handleOk() {
    onChange?.(innerId)
    onDismiss?.()
  }

  function handleSelect(id: string) {
    setInnerId(id)
  }

  return (
    <ModalWrapper onDismiss={onDismiss} title={t('normal.address')} width={840}>
      <Empty data={list} minHeight={400}>
        <div className={styles.list}>
          {list.map((item) => {
            return (
              <Address
                key={item.id}
                operational={false}
                data={item}
                onSelect={handleSelect}
                selected={innerId === item.id}
              />
            )
          })}
        </div>
      </Empty>
      <div className={styles.btnCtn}>
        <Button className={styles.btn} onClick={handleOk}>
          {t('normal.ok')}
        </Button>
      </div>
    </ModalWrapper>
  )
}

const ExistAddressSelect: React.FC<Props> = ({ onChange, chainId }) => {
  const { open } = useModal()
  const { t } = useTranslation()
  const [id, setId] = useState('')
  const { data = [] } = useQuery(
    () => WalletApi.getAllUserWithdrawAddressList(chainId),
    [chainId]
  )
  const [address, setAddress] = useState('')

  function getAddress(id: string) {
    return data.find((item) => item.id === id)?.address ?? ''
  }

  function handleOpen() {
    open(
      <AddressModal
        id={id}
        list={data}
        onChange={(id) => {
          const addr = getAddress(id)
          setId(id)
          onChange?.(addr)
          setAddress(addr)
        }}
      />
    )
  }

  return (
    <div className={styles.existAddressSelect}>
      <LabelInput
        placeholder={t('normal.address')}
        label={t('normal.address')}
        inputExtra={
          <img src={FileIcon} className={styles.icon} onClick={handleOpen} />
        }
        value={address}
        onChange={(e) => {
          setAddress(e.target.value)
          onChange?.(e.target.value)
        }}
      />
    </div>
  )
}

export default React.memo(ExistAddressSelect)
