import React, { useMemo } from 'react'
import styles from './style.module.less'
import { ProfileTable } from '@/components/Table'
import { WithdrawRecord } from '@/services/wallet'
import { TableProps } from 'antd'
import { formatTime } from '@/utils/utils'
import { getTableCopyText } from '@/common/profile'
import { useTranslation } from 'react-i18next'
import { WEB3_BLOCK_HASH_PATH } from '@/config'

type Props = {
  dataSource: WithdrawRecord[]
  hideOrderId?: boolean
  hideFee?: boolean
}

const statusMap: Record<string, string> = {
  1: 'wallet.success',
  2: 'wallet.underReview',
  3: 'wallet.pending',
  4: 'wallet.rejected'
}

const WithdrawTable: React.FC<Props> = ({
  dataSource,
  hideOrderId = false,
  hideFee = false
}) => {
  const { t, i18n } = useTranslation()
  const columns: TableProps<WithdrawRecord>['columns'] = [
    {
      title: t('normal.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'left',
      width: 120,
      render(val, record) {
        return (
          <div className={styles.amount}>
            <img src={record.coinImg} className={styles.currencyIcon} />
            <span className={styles.kind}>
              {val} {record.coinCode}
            </span>
          </div>
        )
      }
    },
    {
      title: t('normal.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 100,
      render(val, record) {
        const text = statusMap[record.status]
        return text ? t(text) : '--'
      }
    },
    {
      title: t('normal.time'),
      dataIndex: 'orderCreateTime',
      key: 'orderCreateTime',
      align: 'center',
      width: 120,
      render(val, record) {
        return formatTime(+record.orderCreateTime)
      }
    },
    {
      title: t('normal.network'),
      dataIndex: 'chainCode',
      key: 'chainCode',
      align: 'center',
      width: 100
    },
    {
      title: t('wallet.assets.receiver'),
      dataIndex: 'toAddress',
      key: 'toAddress',
      align: 'center',
      render(val, record) {
        return record.toAddress
          ? getTableCopyText(record.toAddress, true, record.toAddressUrl)
          : getTableCopyText(record.otherSideUserCode, false)
      }
    },
    {
      title: 'TXID',
      dataIndex: 'txHash',
      key: 'txHash',
      align: 'center',
      render(val, record) {
        return getTableCopyText(
          record.txHash,
          true,
          `${WEB3_BLOCK_HASH_PATH}/${record.txHash}`
        )
      }
    },
    {
      title: t('wallet.assets.networkFee'),
      dataIndex: 'withdrawFee',
      key: 'withdrawFee',
      align: 'center',
      render(val, record) {
        return `${record.withdrawFee} ${record.tokenName}`
      }
    },
    {
      title: t('normal.orderId'),
      dataIndex: 'txId',
      key: 'txId',
      align: 'center',
      render(val, record) {
        return getTableCopyText(record.txId, false)
      }
    }
  ]

  const c = useMemo(() => {
    let list = columns
    if (hideOrderId) {
      list = list.filter((column) => column.key !== 'txId')
    }
    if (hideFee) {
      list = list.filter((column) => column.key !== 'withdrawFee')
    }
    return list
  }, [hideFee, hideOrderId, i18n.language])

  return (
    <ProfileTable
      rowKey={'txId'}
      dataSource={dataSource}
      columns={c}
      pagination={false}
    />
  )
}

export default React.memo(WithdrawTable)
