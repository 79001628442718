import React from 'react'
import styles from './style.module.less'
import { Obj } from '@/common/types'
import { useTranslation } from 'react-i18next'
import NoData from '@/components/NoData'
import type { Column } from '@/common/types'

type Props<T> = {
  columns: Column<T>[]
  dataSource: T[]
  rowKey?: string
  emptyVal?: string
}

function ModalTable<T extends Obj>({
  columns,
  dataSource,
  rowKey = 'id',
  emptyVal = '-'
}: Props<T>) {
  const { t } = useTranslation()
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            {columns.map((column) => {
              return <th key={column.dataIndex as string}>{column.title}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {dataSource.map((item, index) => {
            return (
              <tr key={item[rowKey]}>
                {columns.map((column) => {
                  const node = column.render?.(
                    item,
                    index,
                    column.dataIndex as string
                  )
                  return (
                    <td key={column.dataIndex as string}>
                      {(node ? node : item[column.dataIndex]) ?? emptyVal}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {dataSource.length === 0 && <NoData text={t('feedback.nodata')} />}
    </>
  )
}

export default ModalTable
