import React from 'react'
import { useHistory } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import { WalletApi } from '@/services/wallet'
import RechargeTable from '../../components/RechargeTable'
import { TitleModule } from '../../components'
import Empty from '@/components/Empty'
import { useTranslation } from 'react-i18next'

const Table: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { data: depositRecord } = useQuery(() =>
    WalletApi.getDepositRecord({
      pageNo: 1,
      pageSize: 3,
      orderTypes: [1, 3],
      tokenTypes: [1, 2]
    })
  )

  const { data: dataSource = [] } = depositRecord ?? {}

  return (
    <TitleModule
      title={t('wallet.assets.depositHistory')}
      onClickMore={() => history.push('/profile/currency-record?type=recharge')}
    >
      <Empty data={dataSource}>
        <RechargeTable dataSource={dataSource} hideOrderId />
      </Empty>
    </TitleModule>
  )
}

export default React.memo(Table)
